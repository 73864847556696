import React, { useEffect, useState, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import AOS from "aos";
import "aos/dist/aos.css";
import "primereact/resources/themes/soho-light/theme.css";
import "primeicons/primeicons.css";

import Home from "./components/home/Home";
import Footer from "./components/shared/Footer";
import Header from "./components/shared/Header";
import Sidebar from "./components/shared/Sidebar";
import TopNav from "./components/shared/TopNav";
import Credit from "./components/shared/Credit";
import Login from "./components/authentication/Login";
import Register from "./components/authentication/Register";
import Dashboard from "./components/dashboard/Dashboard";
import FindJobs from "./components/jobs/FindJobs";
import JobDetails from "./components/jobs/JobDetails";
import AppliedJobs from "./components/jobs/AppliedJobs";
import FullProfile from "./components/profile/full-profile";
import AddCommunication from "./components/communication/AddCommunication";
import CandidateProfile from "./components/candidate-profile/CandidateProfile";
import { Toast } from "primereact/toast";
import Checklist from "./components/jobs/Checklist";
import UpdateChecklist from "./components/jobs/UpdateChecklist";
import Questionaries from "./components/jobs/Questionaries";
import AddFeedback from "./components/feedback/AddFeedback";
import CandidateList from "./components/candidate-list/candidate-list";
import CandidateFullProfile from "./components/candidate-list/candidate-profile";
import AddCandidate from "./components/candidate-list/add-candidate";
import { NotificationHelper } from "./shared/helper/notification.helper";
import { isAuthenticated } from "./shared/service/authentication";

const App = () => {
  const location = useLocation();
  let navigate = useNavigate();

  const showRoutes = ["/"];
  const shouldShowHeaderFooter = showRoutes.includes(location.pathname);
  const notDasboardRoutes = ["/", "/login", "/register", "/user-profile"];
  const shouldnotShowTopNavCreditSidebar = notDasboardRoutes.includes(
    location.pathname
  );

  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    NotificationHelper.setToastRef(toast);
    AOS.init({ duration: 1200 });
    AOS.refresh();
    
    if(!notDasboardRoutes.includes(location.pathname) && !isAuthenticated()) {
      navigate("../");
    }

    const sidebarElement = document.getElementById("sidebar");

    if (sidebarElement) {
      const handleHidden = () => {
        setSidebarCollapsed(true);
      };

      const handleShown = () => {
        setSidebarCollapsed(false);
      };

      sidebarElement.addEventListener("hidden.bs.collapse", handleHidden);
      sidebarElement.addEventListener("shown.bs.collapse", handleShown);

      return () => {
        sidebarElement.removeEventListener("hidden.bs.collapse", handleHidden);
        sidebarElement.removeEventListener("shown.bs.collapse", handleShown);
      };
    }
  }, []);
  
  return (
    <>
      <PrimeReactProvider>
        {shouldShowHeaderFooter && <Header />}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/auth" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
        </Routes>
        {shouldShowHeaderFooter && <Footer />}
        {!shouldnotShowTopNavCreditSidebar && isAuthenticated() ? (
          <div className="">
            <div className="d-flex w-100 min-vh-100 vh-100">
              {<Sidebar />}
              {<Toast ref={toast} />}
              <div className="m-0 p-0 pt-0  d-flex flex-column w-100">
                <div
                  className={`w-100 topnav ${
                    isSidebarCollapsed ? "sidebar-collapsed2" : ""
                  }`}
                >
                  {<TopNav />}
                </div>
                <div
                  className={` h-100 overflow-auto main-content p-4 bg_color ${
                    isSidebarCollapsed ? "sidebar-collapsed" : ""
                  }`}
                >
                  <Routes>
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route
                      exact
                      path="/candidate-list"
                      element={<CandidateList />}
                    />
                    <Route
                      exact
                      path="/candidate-list/profile/:candidateId"
                      element={<CandidateFullProfile />}
                    />
                    <Route
                      exact
                      path="/candidate-list/add/:recruiterNumber"
                      element={<AddCandidate />}
                    />
                    <Route exact path="/find-jobs" element={<FindJobs />} />
                    <Route exact path="/feedback" element={<AddFeedback />} />
                    <Route
                      exact
                      path="/job-details/:id/:email"
                      element={<JobDetails />}
                    />
                    <Route
                      exact
                      path="/my-application"
                      element={<AppliedJobs />}
                    />
                    <Route
                      exact
                      path="/add-communication/:applicationNumber"
                      element={<AddCommunication />}
                    />
                    <Route
                      exact
                      path="/checklist/:applicationNumber"
                      element={<Checklist />}
                    />
                    <Route
                      exact
                      path="/checklist/update/:applicationNumber"
                      element={<UpdateChecklist />}
                    />
                    <Route
                      exact
                      path="/questionaries/:applicationNumber"
                      element={<Questionaries />}
                    />
                    <Route exact path="/profile" element={<FullProfile />} />
                  </Routes>
                </div>
                {<Credit />}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <Routes>
          <Route
            exact
            path="/user-profile"
            element={<CandidateProfile />}
          />
        </Routes>
      </PrimeReactProvider>
    </>
  );
}

export default App;
