import React, { useState, useEffect } from "react";
import JobCard from "../jobs/jobCard";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../shared/service/authentication";
import { useMsal } from "@azure/msal-react";
import { useConfig } from "../../config-context";

const TredingJobs = ({ profile }) => {
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const { Endpoints } = useConfig();
  const [loading, setLoading] = useState(false);
  const [idToken, setIdToken] = useState("");
  const { instance, accounts } = useMsal();
  let navigate = useNavigate();

  const handleLogin = async () => {
    try {
      let { idToken } = await instance.loginRedirect();
      setIdToken(idToken);
    } catch (error) {}
  };

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.defaultJobList}/${currentPage}/${pageSize}`,
        HttpMethod.GET
      );
      if (response && Array.isArray(response)) {
        setJobs(
          response.map((item) => ({
            id: item.JobERPRecId,
            code: item.Code,
            description: item.Description,
            location: item.Locations,
            longDescription: item.LongDescription,
            openingDate: item.OpeningDate,
            closingDate: item.ClosingDate,
          }))
        );
      } else {
        setJobs([]);
      }
    } catch (error) {
      setError("Failed to fetch Default Jobs");
    } finally {
      setLoading(false);
    }
  };

  const onExploreMoreJob = () => {
    if (isAuthenticated()) {
      navigate("/find-jobs");
    } else {
      handleLogin();
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [currentPage, pageSize]);

  return (
    <section className="trending-jobs">
      <div className="container">
        <div className="section-title-light">
          <h3 data-aos="fade-up" className="font_bold">
            <span className="primary_color">TRENDING </span>
            <span className="primary_light_color"> JOBS</span>
          </h3>
          <p data-aos="fade-up" className="font_regular">
            Choose your trending dream job & make your future bright.
          </p>
        </div>

        <div className="row mt-5">
          {jobs.map((job, index) => (
            <div
              className="col-lg-4 col-md-6 mb-5"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <JobCard job={job} profile={profile}></JobCard>
            </div>
          ))}
        </div>

        <div className="text-center">
          <button
            onClick={() => onExploreMoreJob()}
            className="explore bg_dark secondary_color font_regular"
            data-aos="fade"
          >
            Explore More Jobs{" "}
            <span className="ms-3">
              <i className="pi pi-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default TredingJobs;
