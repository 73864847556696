import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { ConfirmDialog } from "primereact/confirmdialog";
import EditRecruiterProfile from "./edit-recruiter-profile";
import { Skeleton } from "primereact/skeleton";
import { useConfig } from "../../config-context";

const RecruiterFullProfile = () => {
  const [loading, setLoading] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);
  const [sideBarHeader, setSideBarHeader] = useState(false);
  const [sideBarBody, setSideBarBody] = useState(false);
  const [profile, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const { Endpoints } = useConfig();
  const defaultValue = "N/A";

  useEffect(() => {
    getProfileInfo();
  }, [Endpoints]);

  const getProfileInfo = async () => {
    try {
      setLoading(true);
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getSideBarHeaderTemplate = (title) => (
    <div className="d-flex">
      <span className="me-3  fs-4">
        <button
          type="button"
          className="btn"
          onClick={() => setShowSideBar(false)}
        >
          <i className="pi pi-chevron-left my-auto"></i>
        </button>
      </span>
      <span className="fs-4 font_semibold my-auto">{title}</span>
    </div>
  );

  const onProfileEdit = () => {
    setSideBarHeader(getSideBarHeaderTemplate("Edit Profile Details"));
    setSideBarBody(
      <EditRecruiterProfile
        profile={profile}
        onSideBarClose={() => setShowSideBar(false)}
        onSuccess={onSuccess}
      />
    );
    setShowSideBar(true);
  };

  const onSuccess = () => {
    getProfileInfo();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Profile</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <Sidebar
        className="bg_light primary_colr w-50"
        position="right"
        visible={showSideBar}
        header={sideBarHeader}
        onHide={() => setShowSideBar(false)}
      >
        {sideBarBody}
      </Sidebar>

      <ConfirmDialog />

      <main className="main">
        <div className="page-header">
          <h2>Profile</h2>
        </div>
        <div className="mt-4">
          <div className="row">
            <div className="col-lg-12 mb-5">
              {loading ? (
                <div className="card-box h-100 w-100">
                  <Skeleton height="40rem"></Skeleton>
                </div>
              ) : (
                <div className="card-box h-100">
                  <div className="row image-name-section">
                    <div className="col-4 col-md-2">
                      <img
                        src={ProfileHelper.getProfileImage(profile)}
                        className="rounded thumbnail profile-image"
                        height={200}
                        width={200}
                        alt=""
                      />
                    </div>
                    <div className="col-8 my-auto">
                      <h5 className="font_semibold">
                        {ProfileHelper.getUserFullName(profile)}
                      </h5>

                      <p className="primary_light_color lh-1">
                        {profile?.CurrentJobTitle || ""}
                      </p>

                      <Link
                        className="small text-decoration-underline"
                        onClick={() => onProfileEdit()}
                      >
                        Edit Profile
                      </Link>
                    </div>
                  </div>

                  <div className="personal-info-section">
                    <hr className="my-3" />
                    <h4 className="font_semibold">Personal Information</h4>

                    <div className="row my-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">First Name</h5>
                        <p className="border px-3 py-2">
                          {profile?.FirstName || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">MiddleName Name</h5>
                        <p className="border px-3 py-2">
                          {profile?.MiddleName || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Last Name</h5>
                        <p className="border px-3 py-2">
                          {profile?.LastName || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Organization Name</h5>
                        <p className="border px-3 py-2">
                          {profile?.OrganizationName || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Referance</h5>
                        <p className="border px-3 py-2">
                          {profile?.Referance || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Category</h5>
                        <p className="border px-3 py-2">
                          {profile?.Category || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Website</h5>
                        <p className="border px-3 py-2">
                          {profile?.Website || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Contact No</h5>
                        <p className="border px-3 py-2">
                          {profile?.ContactNo || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Alternate Contact No</h5>
                        <p className="border px-3 py-2">
                          {profile?.AlternateContactNo || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Dob</h5>
                        <p className="border px-3 py-2">
                          {profile?.Dob || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Email</h5>
                        <p className="border px-3 py-2">
                          {profile?.Email || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">FaceBook Link</h5>
                        <p className="border px-3 py-2">
                          {profile?.FaceBookLink || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Twitter Link</h5>
                        <p className="border px-3 py-2">
                          {profile?.TwitterLink || defaultValue}
                        </p>
                      </div>

                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                        <h5 className="mb-3">Linkedin Link</h5>
                        <p className="border px-3 py-2">
                          {profile?.LinkedinLink || defaultValue}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RecruiterFullProfile;
