import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";
import { FileUpload } from "primereact/fileupload";
import { Checkbox } from "primereact/checkbox";
import { decapitalizeKeys } from "../../shared/utils/utils";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { Button } from "primereact/button";
import { useConfig } from "../../config-context";

const UpdateChecklist = () => {
  let { applicationNumber } = useParams();
  const { Endpoints } = useConfig();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setloading] = useState(null);
  const [checklistInfo, setChecklistInfo] = useState(null);
  const [remarkText, setRemarkText] = useState("");
  const [documents, setDocuments] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const fetchChecklist = async () => {
    try {
      const result = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.getApplicationCheckListByNumber}/${applicationNumber}`
      );
      setRemarkText(result?.Data.Remarks || "");
      setChecklistInfo(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  const onDocumentUpload = (event) => {
    const responseText = event?.xhr.response;

    if (responseText) {
      const document = JSON.parse(responseText);
      setDocuments([document]);
    }
  };

  const updateChecklist = async () => {
    const payload = getCheckListPayload();
    setloading(true);
    try {
      await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(Endpoints.applicationCheckList, HttpMethod.PUT, payload);
      setloading(false);
      navigate(`/checklist/${checklistInfo?.ApplicationNumber}`);
    } catch (error) {
      setloading(false);
      setError(error);
    }
  };

  const getCheckListPayload = () => {
    return {
      ...decapitalizeKeys(checklistInfo),
      remarks: remarkText,
      completed: isCompleted ? 1 : 0,
      applicationChecklistDocumentDTOs: documents,
    };
  };

  const onCompleteChange = (event) => {
    setIsCompleted(!!event?.checked);
  };

  useEffect(() => {
    fetchChecklist();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Application Checklist Update </title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header d-flex">
          <Link to={`/checklist/${checklistInfo?.ApplicationNumber}`}>
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
          </Link>
          <span className="fs-3 font_semibold">Update Checklist</span>
        </div>
        <div className="mt-3 card-box checklist-box">
          <div className="card-header">
            {checklistInfo ? (
              <>
                <h5>Subject: {checklistInfo?.Subject}</h5>
                <h5>Description: {checklistInfo?.Description}</h5>
              </>
            ) : (
              <>
                <Skeleton height="2rem"></Skeleton>
                <Skeleton height="2rem"></Skeleton>
              </>
            )}
          </div>
          <div className="form mt-4">
            <div className="form-group mb-3">
              <label htmlFor="remarks">Remarks:</label>
              <Editor
                id="remarks"
                value={remarkText}
                onTextChange={(e) => setRemarkText(e.htmlValue)}
                style={{ height: "120px" }}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="fileUploader">Upload Document</label>
              <FileUpload
                auto
                accept="/*"
                id="fileUploader"
                name="documents[]"
                url={`${Endpoints.baseUrl}/${Endpoints.uploadSingleFileCheckList}`}
                onUpload={onDocumentUpload}
                onRemove={() => {
                  setDocuments([]);
                }}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
              />
            </div>
            <div className="form-group mb-3">
              <div className="d-flex align-items-center">
                <Checkbox
                  inputId="isCompleted"
                  onChange={onCompleteChange}
                  checked={isCompleted}
                />

                <label htmlFor="isCompleted" className="ms-2">
                  Completed
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              label="Cancel"
              disabled={loading}
              onClick={() => {
                navigate(`/checklist/${checklistInfo?.ApplicationNumber}`);
              }}
              className="apply btn rounded-0 mx-2 bg_light dark_outline primary_color font_semibold"
            ></Button>
            <Button
              hidden={checklistInfo?.Completed === "1"}
              label="Submit"
              icon="pi pi-arrow-right"
              iconPos="right"
              loading={loading}
              className="apply btn rounded-0 mx-2 bg_dark  secondary_color font_semibold"
              onClick={updateChecklist}
            ></Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateChecklist;
