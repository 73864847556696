import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { decapitalizeKeys, getPayloadDate } from "../../shared/utils/utils";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { FileUpload } from "primereact/fileupload";
import RecuriterProfileForm from "../shared/forms/profile-forms/recruiter-profile-form";
import { useConfig } from "../../config-context";

const EditRecruiterProfile = ({ profile, onSideBarClose, onSuccess }) => {
  const [formValue, setFormValue] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageData, setImageData] = useState(profile?.imageURL);
  const { Endpoints } = useConfig();
  
  useEffect(() => {
    if (profile) {
      setFormValue({
        ...profile,
      });

    }
  }, [profile]);

  const getFormValue = (value) => {
    setFormValue(value);
  };

  const getCountries = (value) => {
    setCountryList(value);
  };

  const getStates = (value) => {
    setStateList(value);
  };

  const getCities = (value) => {
    setCityList(value);
  };

  const getNationalities = (value) => {
    setNationalityList(value);
  };

  const updateRecruiterProfile = async (payload) => {
    try {
      setLoading(true);
      await new ProfileHelper(Endpoints).updateRecuriterProfileInfo(payload, HttpMethod.PUT);
      setLoading(false);
      onSideBarClose(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error);

    }
  };

  const onImageUpload = (event) => {
    const responseText = event?.xhr?.response || event?.xhr?.responseText;
    setImageData(JSON.parse(responseText || null));
  };

  const onRecuriterFormSubmit = () => {
    let payload = decapitalizeKeys(formValue);

    const selectedCountry = countryList.find(
      ({ ERPRecId }) => ERPRecId === payload?.countryERPRecId
    );
    const selectedState = stateList.find(
      ({ ERPRecId }) => ERPRecId === payload?.stateERPRecId
    );
    const selectedCity = cityList.find(
      ({ ERPRecId }) => ERPRecId === payload?.cityERPRecId
    );
    const selectedNationality = nationalityList.find(
      ({ ERPRecId }) => ERPRecId === payload?.nationalityId
    );
    const currentISODate = new Date().toISOString();

    payload = {
      ...payload,
      dob: payload.dob ? getPayloadDate(new Date(payload.dob)) : "",
      email: ProfileHelper.getUserEmail(),
      country: selectedCountry?.Code,
      countryCode: selectedCountry?.Code || null,
      state: selectedState?.Code,
      stateCode: selectedState?.Code || null,
      city: selectedCity?.Code || null,
      cityCode: selectedCity?.Code || null,
      nationality: selectedNationality?.Code,
      imageURL: imageData && imageData.SFilePath ? imageData.SFilePath : (profile?.ImageURL || ""),
      dataArea: "",
      erpRecId: 0,
      generatedDate: profile.CreatedDate,
      isSynced: 0,
      modifiedDate: currentISODate,
      number: profile.Number,
      rowVersion: null,
      userTypeId: 3,
      recruiterOrganizationNumber: payload.recruiterOrganizationNumber ? payload.recruiterOrganizationNumber : profile.RecruiterOrganizationNumber,

    };

    updateRecruiterProfile(payload);
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="form-group row m-0" style={{ overflow: "auto" }}>
          <RecuriterProfileForm
            isEditMode={true}
            profile={profile}
            getFormValue={getFormValue}
            getCountries={getCountries}
            getStates={getStates}
            getCities={getCities}
            getNationalities={getNationalities}
          ></RecuriterProfileForm>

          <div className="d-flex flex-wrap mt-3">
            <FileUpload
              auto
              mode="basic"
              className="me-3"
              chooseLabel={imageData?.SOrgFileName || "Upload Profile Image"}
              chooseOptions={{
                icon: imageData?.SOrgFileName
                  ? "pi pi-check-circle"
                  : "pi pi-upload",
                className: imageData?.SOrgFileName
                  ? "p-button-success"
                  : "p-button-primary",
              }}
              url={`${Endpoints.baseUrl}/${Endpoints.uploadPhoto}`}
              accept="image/png, image/jpeg, image/jpg"
              onUpload={(response) => onImageUpload(response)}
            />

          </div>
        </div>

        <div className="w-100 mt-auto">
          <div className="text-end mt-3">
            <Button
              className="apply btn rounded py-2 px-3 mx-2 bg_light dark_outline primary_color font_semibold"
              label="Cancel"
              onClick={onSideBarClose}
            ></Button>
            <Button
              className="apply btn rounded py-2 px-3 mx-2 bg_dark  secondary_color font_semibold"
              label="Update"
              icon="pi pi-arrow-right"
              iconPos="right"
              loading={loading}
              onClick={() => onRecuriterFormSubmit(formValue)}
            ></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRecruiterProfile;

