import React, { useState, useEffect } from "react";
import { FormControlType } from "../../../../shared/enum/form-control-type.enum";
import { MasterDataHelper } from "../../../../shared/helper/master-data.helper";
import CustomFormGroup from "../custom-forms";
import { useConfig } from "../../../../config-context";

const PersonalInfoForm = ({
  profile,
  getFormValue,
  getCountries,
  getStates,
  getCities,
  getNationalities,
  formInputClass,
  formCheckboxClass,
}) => {
  const inputClass = formInputClass || "col-lg-4 col-md-6 col-12 mb-3";
  const checkboxClass = formCheckboxClass || "col-lg-5 col-md-6 col-12 mb-3";

  const [formValue, setFormValue] = useState({});
  const [titleList, setTitleList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const { Endpoints } = useConfig();

  useEffect(() => {
    getTitleList();
    getEducationList();
    getCountryList();

    setGenderList([
      { Code: "None", ERPRecId: null },
      { Code: "Male", ERPRecId: 1 },
      { Code: "Female", ERPRecId: 2 },
      { Code: "Non-Specific", ERPRecId: 3 },
    ]);

    setStatusList([
      { Code: "Married", ERPRecId: 1 },
      { Code: "Single", ERPRecId: 2 },
      { Code: "Seperate", ERPRecId: 3 },
      { Code: "Widow", ERPRecId: 4 },
      { Code: "Divorced", ERPRecId: 5 },
    ]);

    if (profile) {
      setFormValue({
        ...profile,
      });

      if (profile?.CountryERPRecId) {
        getStateList(profile.CountryERPRecId);
      }
      if (profile?.StateERPRecId) {
        getCityList(profile.StateERPRecId);
      }
    }
  }, [Endpoints]);

  const getTitleList = async () => {
    const results = await new MasterDataHelper(Endpoints).fetchTitlesList();
    setTitleList(results);
  };

  const getEducationList = async () => {
    const results = await new MasterDataHelper(Endpoints).fetchEducationLevelList();
    setEducationList(results);
  };

  const getCountryList = async () => {
    const nationalityList = await new MasterDataHelper(Endpoints).fetchCountryList();
    const countryList = new MasterDataHelper(Endpoints).getConvertedCountries(nationalityList);
    setNationalityList(nationalityList);
    getNationalities(nationalityList);
    setCountryList(countryList);
    getCountries(countryList);
  };

  const getStateList = async (countryId) => {
    const stateList = await new MasterDataHelper(Endpoints).fetchStateList(countryId);
    if ((stateList || []).length <= 1) {
      const cityList = await new MasterDataHelper(Endpoints).fetchCityListViaCountryId(
        countryId
      );
      setCityList(cityList);
      getCities(cityList);
    }
    setStateList(stateList);
    getStates(stateList);
  };

  const getCityList = async (stateId) => {
    const cityList = await new MasterDataHelper(Endpoints).fetchCityList(stateId);
    setCityList(cityList);
    getCities(cityList);
  };

  const handleSubmit = (value) => {
    setFormValue(value);
    getFormValue(value);
  };

  const onCountryChange = (value) => {
    if (value) {
      getStateList(value);
    } else {
      setStateList([]);
      getStates([]);
      setCityList([]);
      getCities([]);
    }
  };

  const onStateChange = (value) => {
    if (value) {
      getCityList(value);
    } else {
      setCityList([]);
      getCities([]);
    }
  };

  const formGroupList = [
    {
      label: "Personal Title",
      placeholder: "Select Title",
      propertyName: "PersonalTitleERPRecId",
      options: titleList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
    },
    {
      label: "First Name",
      placeholder: "First Name",
      propertyName: "FirstName",
      type: FormControlType.TEXT,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Middle Name",
      placeholder: "Middle Name",
      propertyName: "MiddleName",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Last Name",
      placeholder: "Last Name",
      propertyName: "LastName",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Current Job",
      placeholder: "Current Job",
      propertyName: "CurrentJobTitle",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Highest Degree",
      placeholder: "Select Highest Degree",
      propertyName: "HighestDegreeERPRecId",
      options: educationList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
    },
    {
      label: "Country",
      placeholder: "Select Country",
      propertyName: "CountryERPRecId",
      optionLabel: "Description",
      options: countryList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
      onChange: onCountryChange,
    },
    {
      label: "State",
      placeholder: "Select State",
      propertyName: "StateERPRecId",
      options: stateList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
      onChange: onStateChange,
    },
    {
      label: "City",
      placeholder: "Select City",
      propertyName: "CityERPRecId",
      options: cityList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
    },
    {
      label: "Street",
      placeholder: "Street",
      propertyName: "Street",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Zip Code",
      placeholder: "Zip Code",
      propertyName: "ZipCode",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Nationality",
      placeholder: "Select Nationality",
      propertyName: "NationalityId",
      options: nationalityList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Birth Date",
      propertyName: "Dob",
      type: FormControlType.DATE,
      class: inputClass,
    },
    {
      label: "Contact",
      placeholder: "Contact",
      propertyName: "ContactNo",
      type: FormControlType.NUMBER,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Alternate Contact",
      placeholder: "Alternate Contact",
      propertyName: "AlternateContactNo",
      type: FormControlType.NUMBER,
      class: inputClass
    },
    {
      label: "Gender",
      placeholder: "Select Gender",
      propertyName: "Gender",
      options: genderList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
    },
    {
      label: "Maritial Status",
      placeholder: "Select Maritial Status",
      propertyName: "MaritalStatus",
      options: statusList,
      type: FormControlType.DROPDOWN,
      class: inputClass,
    },
    {
      label: "Facebook Link",
      placeholder: "Facebook Link",
      propertyName: "FaceBookLink",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Twitter Link",
      placeholder: "Twitter Link",
      propertyName: "TwitterLink",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Linkedin Link",
      placeholder: "Linkedin Link",
      propertyName: "LinkedinLink",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Previous Employee",
      propertyName: "PreviousEmployee",
      type: FormControlType.CHECKBOX,
      class: checkboxClass,
    },
    {
      label: "Is Disabled",
      propertyName: "Disabled",
      type: FormControlType.CHECKBOX,
      class: checkboxClass,
    },
    {
      label: "Can Travel",
      propertyName: "CanTravel",
      type: FormControlType.CHECKBOX,
      class: checkboxClass,
    },
    {
      label: "Can Relocate",
      propertyName: "CanRelocate",
      type: FormControlType.CHECKBOX,
      class: checkboxClass,
    },
  ];

  return (
    <>
      <CustomFormGroup
        formGroupList={formGroupList}
        formValue={formValue}
        setFormValue={handleSubmit}
      ></CustomFormGroup>
    </>
  );
};

export default PersonalInfoForm;
