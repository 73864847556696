import React, { useState } from "react";

const Credit = () => {
  const year = useState(new Date().getFullYear());

  return (
      <footer className="w-100 footer text-center bg_dark py-3">
        <div className="container">
          <span className="text-white">© {year} THE TMRW FOUNDATION</span>
        </div>
      </footer>
  );
};

export default Credit;
