import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import JobCard from "./jobCard";
import { convertDateTime } from "../../shared/utils/utils";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { Link } from "react-router-dom";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { useConfig } from "../../config-context";

const FindJobs = () => {
  const [isGridView, setIsGridView] = useState(true);
  const { Endpoints } = useConfig();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [jobs, setJobs] = useState([]);
  const [profile, setProfileData] = useState(null);

  const filteredJobs = jobs.filter((job) => {
    return Object.values(job).some((value) =>
      value.toString().toLowerCase().includes(globalFilterValue.toLowerCase())
    );
  });

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {}
  };

  const fetchJobs = async () => {
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.defaultJobList}/1/100`,
        HttpMethod.GET
      );
      if (response && Array.isArray(response)) {
        setJobs(
          response.map((item) => ({
            id: item.JobERPRecId,
            code: item.Code,
            description: item.Description,
            location: item.Locations,
            longDescription: item.LongDescription,
            openingDate: item.OpeningDate,
            closingDate: item.ClosingDate,
          }))
        );
      } else {
        setJobs([]);
      }
    } catch (error) {}
  };

  const closingDateBodyTemplate = (filteredJobs) => {
    return convertDateTime(filteredJobs.closingDate);
  };

  const openingDateBodyTemplate = (filteredJobs) => {
    return convertDateTime(filteredJobs.openingDate);
  };

  useEffect(() => {
    getProfileData();
    fetchJobs();
  }, [Endpoints]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Find Jobs</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header">
          <h2>Find Jobs</h2>
        </div>

        <div className="mt-4 card-box">
          <input
            type="text"
            value={globalFilterValue}
            onChange={(e) => setGlobalFilterValue(e.target.value)}
            placeholder="Search jobs , locations,..."
            className="p-inputtext p-component me-2 w-100"
          />
        </div>

        <div className="mt-4 card-box">
          <div className="w-100 d-flex justify-content-between">
            <h3>Search Results</h3>
            <div className="icon">
              <i
                role="button"
                className={`fs-5 ms-2 p-2 pi pi-microsoft ${
                  isGridView ? "bg_dark secondary_color" : "text_dark"
                }`}
                onClick={() => setIsGridView(true)}
              ></i>

              <i
                role="button"
                className={`pi pi-list fs-5 ms-3 p-2 ${
                  isGridView ? "text_dark" : "bg_dark secondary_color"
                }`}
                onClick={() => setIsGridView(false)}
              ></i>
            </div>
          </div>
          <div className="row mt-3" data-aos="fade" data-aos-duration="500">
            {isGridView ? (
              (filteredJobs || []).length > 0 ? (
                filteredJobs.map((job, index) => (
                  <div className="col-lg-4 col-md-6 mb-3 h-auto">
                    <JobCard job={job} profile={profile} />
                  </div>
                ))
              ) : (
                ""
              )
            ) : (
              <div className="table-responsive">
                <DataTable
                  showGridlines
                  value={filteredJobs}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column
                    field="code"
                    header="Position"
                    sortable
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="location"
                    header="Location"
                    sortable
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="description"
                    header="Description"
                    sortable
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="openingDate"
                    header="Opening date"
                    body={openingDateBodyTemplate}
                    sortable
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="closingDate"
                    header="Closing&nbsp;Date"
                    body={closingDateBodyTemplate}
                    sortable
                    style={{ width: "20%" }}
                  ></Column>
                  <Column
                    field="action"
                    header="Action"
                    body={(rowData) => (
                      <div className="d-flex">
                        <button className="btn d-inline border mx-1">
                          <Link
                            to={`/job-details/${rowData.id}/${profile?.Email}`}
                          >
                            {" "}
                            <i className="pi pi-arrow-right"></i>
                          </Link>
                        </button>
                      </div>
                    )}
                  />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default FindJobs;
