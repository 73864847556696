import React, { useState, useEffect } from "react";
import { FormControlType } from "../../../../shared/enum/form-control-type.enum";
import CustomFormGroup from "../custom-forms";

const ExperienceAddForm = ({
  selectedExperience,
  getFormValue,
  formInputClass,
}) => {
  const inputClass = formInputClass || "col-md-6 col-12 mb-3";
  const [formValue, setFormValue] = useState({});

  useEffect(() => {
    if (selectedExperience) {
      setFormValue({
        ...selectedExperience,
      });
    }
  }, []);

  const formGroupList = [
    {
      label: "Employer",
      placeholder: "Employer",
      propertyName: "Employer",
      type: FormControlType.TEXT,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Position",
      placeholder: "Position",
      propertyName: "Position",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Internet Address",
      placeholder: "Internet Address",
      propertyName: "EmployerURL",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Telephone",
      placeholder: "Telephone",
      propertyName: "EmployerContactNo",
      type: FormControlType.NUMBER,
      class: inputClass,
    },
    {
      label: "Start Date",
      propertyName: "StartDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "End Date",
      propertyName: "EndDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Location",
      placeholder: "Location",
      propertyName: "EmployerLocation",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Notes",
      placeholder: "Notes",
      propertyName: "Notes",
      type: FormControlType.TEXT,
      class: inputClass,
    },
  ];

  const handleSubmit = (value) => {
    setFormValue(value);
    getFormValue(value);
  };

  return (
    <>
      <CustomFormGroup
        formGroupList={formGroupList}
        formValue={formValue}
        setFormValue={handleSubmit}
      ></CustomFormGroup>
    </>
  );
};

export default ExperienceAddForm;
