import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { convertDate } from "../../shared/utils/utils";
import { Link, useParams } from "react-router-dom";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { Skeleton } from "primereact/skeleton";
import { useConfig } from "../../config-context";

const CandidateFullProfile = () => {
  let { candidateId } = useParams();
  const [profile, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const defaultValue = "N/A";
  const { Endpoints } = useConfig();

  useEffect(() => {
    getProfileInfo();
  }, []);

  const getProfileInfo = async () => {
    try {
      const result = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.candidates}/${candidateId}`
      );
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Profile</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <main className="main">
        <div className="page-header d-flex">
          <Link to="/candidate-list">
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
          </Link>
          <h2>Candidate Profile</h2>
        </div>
        {profile ? (
          <div className="mt-4 card-box">
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6 mb-5">
                <div className="card-box h-100">
                  <div className="row image-name-section">
                    <div className="col-4">
                      <img
                        src={ProfileHelper.getProfileImage(profile)}
                        className="rounded profile-image"
                        height={130}
                        width={130}
                        alt=""
                      />
                    </div>
                    <div className="col-8">
                      <h5 className="font_semibold">
                        {ProfileHelper.getUserFullName(profile)}
                      </h5>

                      <p className="primary_light_color lh-1">
                        {profile?.CurrentJobTitle || ""}
                      </p>
                    </div>
                  </div>

                  <div className="personal-info-section">
                    <hr className="my-3" />
                    <h4 className="font_semibold">Personal Information</h4>
                    <div className="personal-info">
                      <p className="font_semibold primary_color my-3">
                        <i className="pi pi-inbox"></i> :{" "}
                        <span className="primary_light_color fw-normal">
                          {profile?.Email || defaultValue}
                        </span>
                      </p>

                      <p className="font_semibold primary_color my-3">
                        <i className="pi pi-phone"></i> :{" "}
                        <span className="primary_light_color fw-normal">
                          {profile?.ContactNo || defaultValue}
                        </span>
                      </p>

                      {profile?.AlternateContactNo ? (
                        <p className="font_semibold primary_color my-3">
                          <i className="pi pi-phone"></i> :{" "}
                          <span className="primary_light_color fw-normal">
                            {profile?.AlternateContactNo || defaultValue}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}

                      {ProfileHelper.getFullAdress(profile) ? (
                        <p className="font_semibold primary_color my-3">
                          <i className="pi pi-map-marker"></i> :{" "}
                          <span className="primary_light_color fw-normal">
                            {ProfileHelper.getFullAdress(profile)}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}

                      {ProfileHelper.getUserDate(profile) ? (
                        <p className="font_semibold primary_color my-3">
                          <i className="pi pi-gift"></i> :{" "}
                          <span className="primary_light_color fw-normal">
                            {ProfileHelper.getUserDate(profile)}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}

                      <p className="font_semibold primary_color my-3">
                        <i className="pi pi-key"></i> :{" "}
                        <span className="primary_light_color fw-normal">
                          {ProfileHelper.getUserGender(profile)}
                        </span>
                      </p>
                    </div>
                  </div>

                  {profile?.ResumeURL ? (
                    <div className="documents-section">
                      <hr className="my-3" />
                      <h4 className="font_semibold">Documents</h4>

                      <div className="documents">
                        <div className="d-flex dark_outline rounded p-2 my-2">
                          <span>Resume.docx</span>
                          <span className="ms-auto">
                            <a
                              href={profile.ResumeURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                type="button"
                                className="pi pi-download mx-2"
                              ></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-12 col-lg-8 col-md-6 mb-5">
                <div className="card-box h-100">
                  <div className="experience-section">
                    <div className="title d-flex">
                      <h4 className="font_semibold">Professional Experience</h4>
                    </div>

                    <div className="experience">
                      {ProfileHelper.getExperience(profile).map(
                        (experience) => (
                          <div className="d-flex my-3">
                            <div className="details">
                              <h5 className="font_regular">
                                {experience?.Employer || defaultValue} -{" "}
                                {experience?.Position || defaultValue}
                              </h5>
                              <p className="mb-0 font_regular primary_light_color">
                                From {convertDate(experience?.StartDate)} To{" "}
                                {convertDate(experience?.EndDate)} - Contact:{" "}
                                {experience?.EmployerContactNo || defaultValue}
                              </p>
                              <p className="mb-0 font_regular primary_light_color">
                                Location:{" "}
                                {experience?.EmployerLocation || defaultValue}{" "}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="education-section">
                    <hr className="my-4" />
                    <div className="title d-flex">
                      <h4 className="font_semibold">Education Summary</h4>
                    </div>

                    {ProfileHelper.getEducationSummary(profile).map(
                      (education) => (
                        <div className="education">
                          <div className="d-flex my-3">
                            <div>
                              <h5 className="font_regular">
                                {education?.Code || ""}
                              </h5>
                              <p className="font_regular primary_light_color">
                                From {convertDate(education?.StartDate)} -To{" "}
                                {convertDate(education?.EndDate)} - CGPA:{" "}
                                {education?.AvgGrade || defaultValue} - Scale:{" "}
                                {education?.Scale || defaultValue}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className="skills-section">
                    <hr className="my-4" />
                    <div className="title d-flex">
                      <h4 className="font_semibold">Skills Summary</h4>
                    </div>

                    <div className="skills">
                      {ProfileHelper.getSkillsSummary(profile).map((skill) => (
                        <div className="d-flex my-3">
                          <div>
                            <h5 className="font_regular">
                              {skill?.SkillCode || defaultValue} -{" "}
                              {skill?.YearOfExperience || defaultValue} Years
                            </h5>
                            <p className="font_regular primary_light_color">
                              {convertDate(skill?.LevelDate)} - Proficient (
                              {skill?.SkillLevelCode || "0"} Star)
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="certificates-section">
                    <hr className="my-4" />
                    <div className="title d-flex">
                      <h4 className="font_semibold">Certificates</h4>
                    </div>
                    <div className="certificates">
                      {ProfileHelper.getCertificates(profile).map(
                        (certificate) => (
                          <div className="d-flex my-3">
                            <div>
                              <h5 className="font_regular">
                                {certificate?.Code || ""} - From{" "}
                                {convertDate(certificate?.StartDate)} -To{" "}
                                {convertDate(certificate?.EndDate)}
                              </h5>
                              <p className="font_regular primary_light_color">
                                Renewel :{" "}
                                {certificate?.RenewalRequired === "1"
                                  ? "Yes"
                                  : "No"}
                                <br />
                                Note : {certificate?.Notes}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4 card-box">
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <Skeleton height="7rem"></Skeleton>
              </div>
              <div className="col-12 col-lg-8 col-md-6">
                <Skeleton height="7rem"></Skeleton>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default CandidateFullProfile;
