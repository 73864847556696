import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { convertDateTime } from "../../shared/utils/utils";

const MyApplication = ({ profile, appliedjobs }) => {
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const filteredJobs = appliedjobs.filter((job) => {
    return (
      job.code
        .toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      job.description
        .toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      job.location
        .toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase())
    );
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    initFilters();
  }, [profile]);

  return (
    <div className="card-box p-3 h-100">
      <div className="d-inline w-100">
        {/* <i className="pi pi-external-link primary_light_color float-end"></i> */}
        <h5 className="mb-0 primary_light_color">My Applications</h5>
      </div>
      <div className="my-4">
        <div className="p-inputgroup d-flex">
          <InputText
            type="text"
            placeholder="Search"
            aria-label="Search"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
          />
          <button className="bg_dark text-white p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </button>
        </div>
      </div>
      <div>
        <DataTable
          className=""
          showGridlines
          value={filteredJobs}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="code" header="Position" sortable></Column>
          <Column field="description" header="Description" sortable></Column>

          <Column
            field="appliedDate"
            header="Applied Date"
            sortable
            body={(rowData) =>
              rowData?.appliedDate ? convertDateTime(rowData?.appliedDate) : ""
            }
          ></Column>

          <Column field="readableStatus" header="Status" sortable></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default MyApplication;
