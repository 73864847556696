import React, { useState, useEffect } from "react";

const TopNav = () => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("center");
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const show = (position) => {
    setPosition(position);
    setVisible(true);
  };

  useEffect(() => {
    setLanguageList([
      {
        id: 1,
        title: "English",
      },
      {
        id: 2,
        title: "Arabic",
      },
      {
        id: 3,
        title: "Hindi",
      },
      {
        id: 4,
        title: "Spanish",
      },
    ]);
  }, []);

  return (
    <nav className="navbar navbar-expand navbar-light bg_dark py-2">
      <div className="container-fluid p-0">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <button
                data-bs-target="#sidebar"
                data-bs-toggle="collapse"
                className="text-decoration-none nav-link "
              >
                <i className="pi pi-list fs-3 text-white"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default TopNav;
