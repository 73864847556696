import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../config-context";

const Sidebar = () => {
  let navigate = useNavigate();
  const { Endpoints } = useConfig();
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [profile, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth >= 1300);
    };
    handleResize();
    getProfileData();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [Endpoints]);

  const Logout = async () => {
    try {
      await instance.logoutRedirect();
      setIdToken("");
    } catch (error) {}
  };

  return (
    <>
      <div className="">
        <div
          id="sidebar"
          className={`sidebar collapse collapse-horizontal border-end bg_dark ${
            showSidebar ? "show" : ""
          }`}
        >
          <div
            id="sidebar-nav"
            className="list-group border-0 rounded-0 text-sm-start h-100"
          >
            <div className="d-inline py-4 ps-3">
              <span
                className="float-end text-white me-2 cross-icon"
                data-bs-target="#sidebar"
                data-bs-toggle="collapse"
              >
                <i className="pi pi-times-circle fs-4"></i>
              </span>
              <img
                src="https://tmrw.com/wp-content/uploads/Logo_Quer_weiss-1.png"
                alt="logo"
                id="logo"
                role="button"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>

            <div className="sidebar-links h-100" style={{ overflow: "auto" }}>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `${
                    isActive ? "isActive" : "navLink"
                  } list-group-item w-100 py-3 my-1 font_semibold d-inline-block border-0`
                }
                data-bs-parent="#sidebar"
              >
                <i className="pi pi-microsoft"></i>{" "}
                <span className="ms-2"> Dashboard</span>
              </NavLink>
              <>
                {profile?.UserTypeId == 2 ? (
                  <>
                    <NavLink
                      to="/find-jobs"
                      className={({ isActive }) =>
                        `${
                          isActive ? "isActive" : "navLink"
                        } list-group-item w-100 py-3 my-1 font_semibold d-inline-block border-0`
                      }
                      data-bs-parent="#sidebar"
                    >
                      <i className="pi pi-search"></i>
                      <span className="ms-2"> Find Jobs</span>
                    </NavLink>

                    <NavLink
                      to="/my-application"
                      className={({ isActive }) =>
                        `${
                          isActive ? "isActive" : "navLink"
                        } list-group-item w-100 py-3 my-1 font_semibold d-inline-block border-0`
                      }
                      data-bs-parent="#sidebar"
                    >
                      <i className="pi pi-server"></i>{" "}
                      <span className="ms-2"> Application</span>
                    </NavLink>

                    <NavLink
                      to="/feedback"
                      className={({ isActive }) =>
                        `${
                          isActive ? "isActive" : "navLink"
                        } list-group-item w-100 py-3 my-1 font_semibold d-inline-block border-0`
                      }
                      data-bs-parent="#sidebar"
                    >
                      <i className="pi pi-comments"></i>{" "}
                      <span className="ms-2"> Feedback</span>
                    </NavLink>

                    <NavLink
                      to="/profile"
                      className={({ isActive }) =>
                        `${
                          isActive ? "isActive" : "navLink"
                        } list-group-item w-100 py-3 my-1 font_semibold d-inline-block border-0`
                      }
                      data-bs-parent="#sidebar"
                    >
                      <i className="pi pi-user-plus"></i>{" "}
                      <span className="ms-2"> Profile</span>
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}

                {profile?.UserTypeId == 3 ? (
                  <>
                    <NavLink
                      to="/candidate-list"
                      className={({ isActive }) =>
                        `${
                          isActive ? "isActive" : "navLink"
                        } list-group-item w-100 py-3 my-1 font_semibold d-inline-block border-0`
                      }
                      data-bs-parent="#sidebar"
                    >
                      <i className="pi pi-list"></i>
                      <span className="ms-2">Candidate List</span>
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
            <AuthenticatedTemplate>
              <button
                onClick={() => Logout()}
                className="list-group-item w-100 text-start my-1 font_semibold bg_dark text-white d-inline-block border-0"
                data-bs-parent="#sidebar"
              >
                <i className="pi pi-sign-out"></i>{" "}
                <span className="ms-2"> Log Out</span>
              </button>
            </AuthenticatedTemplate>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
