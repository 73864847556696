import { HttpMethod } from "../enum/http-method.enum";
import { SubscriberHelper } from "./subscriber.helper";

export class MasterDataHelper {

  constructor(endpoints) {
    this.Endpoints = endpoints;
  }

  async fetchTitlesList() {
    const endpoint = "Masters/personaltitles";
    return await this.fetchData(endpoint);
  }

  async fetchEducationLevelList() {
    const endpoint = "Masters/educationlevel";
    return await this.fetchData(endpoint);
  }

  async fetchCountryList() {
    const endpoint = "Masters/countries";
    return await this.fetchData(endpoint);
  }

  async fetchStateList(countryId) {
    const endpoint = `Masters/country/${countryId}/states`;
    return await this.fetchData(endpoint);
  }

  async fetchCityList(stateId) {
    const endpoint = `Masters/state/${stateId}/cities`;
    return await this.fetchData(endpoint);
  }

  async fetchCityListViaCountryId(countryId) {
    const endpoint = `Masters/country/${countryId}/cities`;
    return await this.fetchData(endpoint);
  }

  async fetchEductionList() {
    const endpoint = `Masters/educations`;
    return await this.fetchData(endpoint);
  }
  
  async fetchSkillsList() {
    const endpoint = `Masters/skills`;
    return await this.fetchData(endpoint);
  }

  async fetchSkillLevelsList() {
    const endpoint = `Masters/skilllevels`;
    return await this.fetchData(endpoint);
  }

  async fetchCertificateTypesList() {
    const endpoint = `Masters/certificatetypes`;
    return await this.fetchData(endpoint);
  }

  getConvertedCountries(countryList) {
    return countryList.map((country) => {
      country.Description = `${country.Code} ${
        country?.Description ? ` - (${country.Description})` : ""
      }`;
      return country;
    });
  }

  async fetchData(endpoint) {
    try {
      const result = await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(`${endpoint}`, HttpMethod.GET);
      return result?.Data || [];
    } catch (error) {
      throw error;
    }
  }
}
