import React, { useState, useEffect } from "react";
import { FormControlType } from "../../../../shared/enum/form-control-type.enum";
import CustomFormGroup from "../custom-forms";
import { MasterDataHelper } from "../../../../shared/helper/master-data.helper";
import { useConfig } from "../../../../config-context";

const CertificateAddForm = ({
  selectedCertificate,
  getFormValue,
  formInputClass,
}) => {
  const inputClass = formInputClass || "col-md-6 col-12 mb-3";
  const [formValue, setFormValue] = useState({});
  const [certificateList, setCertificateList] = useState([]);
  const { Endpoints } = useConfig();

  const getCertificateList = async () => {
    const results = await new MasterDataHelper(
      Endpoints
    ).fetchCertificateTypesList();
    setCertificateList(results);
  };

  useEffect(() => {
    getCertificateList();

    if (selectedCertificate) {
      setFormValue({
        ...selectedCertificate,
      });
    }
  }, [Endpoints]);

  const formGroupList = [
    {
      label: "Certificate",
      placeholder: "Select Certificate",
      propertyName: "CertificateTypeERPRecId",
      type: FormControlType.DROPDOWN,
      options: certificateList,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Notes",
      propertyName: "Notes",
      type: FormControlType.TEXT,
      class: inputClass,
    },
    {
      label: "Start Date",
      propertyName: "StartDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "End Date",
      propertyName: "EndDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Renewal Required",
      placeholder: "RenewalRequired",
      type: FormControlType.CHECKBOX,
      class: inputClass,
    },
  ];

  const handleSubmit = (value) => {
    setFormValue(value);
    getFormValue(value);
  };

  return (
    <>
      <CustomFormGroup
        formGroupList={formGroupList}
        formValue={formValue}
        setFormValue={handleSubmit}
      ></CustomFormGroup>
    </>
  );
};

export default CertificateAddForm;
