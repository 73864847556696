import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { convertDate } from "../../shared/utils/utils";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { useConfig } from "../../config-context";

const AddCommunication = () => {
  const navigate = useNavigate();
  let { applicationNumber } = useParams();
  const { Endpoints } = useConfig();
  const [error, setError] = useState(null);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [communications, setCommunications] = useState([]);
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileUploadRef = useRef(null);

  const onFileUpload = async (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await fetch(
        `${Endpoints.baseUrl}/Upload/UploadSingleFileWithRandomName`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (response) {
        const uploadedFilesData = Array.isArray(data) ? data : [data];
        setUploadedFiles((prev) => [...prev, ...uploadedFilesData]);
      }
    } catch (error) {}
  };

  const onTemplateSelect = async (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
    onFileUpload(files);
  };

  const onTemplateUpload = async (e) => {
    let _totalSize = 0;
    let files = e.files;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    onFileUpload(files);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-center" style={{ width: "40%" }}>
          <span className="d-flex flex-column text-start ms-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined rounded-circle p-button-danger ms-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="text-center">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>

        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5 d-block"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const fetchCommunications = async () => {
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.applicationCommunications}/AppNumber/${applicationNumber}`,
        HttpMethod.GET
      );
      if (response) {
        setCommunications(response.Data);
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    const jsonData = {
      DataArea: "",
      ERPRecId: 0,
      Number: 0,
      ApplicationNumber: applicationNumber,
      CommunicationDirection: 1,
      Subject: subject,
      Message: text,
      Submitted: 0,
      SubmittedDateTime: new Date().toISOString(),
      IsSynced: 0,
      RowVersion: "",
      JobTitle: job.code,
      applicationCommunicationDocumentAddDTO: uploadedFiles,
    };

    setLoading(true);

    try {
      await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        Endpoints.applicationCommunications,
        HttpMethod.POST,
        jsonData
      );

      fetchCommunications();
      setSubject("");
      setText("");
      setUploadedFiles([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchJobDetails = async () => {
    setLoading(true);
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.jobDetailsApplication}/${applicationNumber}`,
        HttpMethod.GET
      );
      if (response) {
        setJob({
          jobid: response.Data.JobERPRecId,
          code: response.Data.Code,
          description: response.Data.Description,
          location: response.Data.Locations,
          longDescription: response.Data.LongDescription,
          openingDate: response.Data.OpeningDate,
          closingDate: response.Data.ClosingDate,
          status: response.Data.Status,
          applicationNumber: response.Data.Applications,
        });
      } else {
        setJob(null);
      }
    } catch (error) {
      setError("Failed to fetch job details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommunications();
    fetchJobDetails();
  }, [Endpoints]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Application Comunication </title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header d-flex">
          <Link to="/my-application">
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
          </Link>
          <span className="fs-3 font_semibold">{job?.code}</span>
        </div>
        {communications.length ? (
          <div className="mt-3 card-box communiction-box">
            {communications.map((communication, index) => (
              <div
                className={
                  communication?.CommunicationDirection === 1
                    ? "d-flex justify-content-end"
                    : "d-flex justify-content-start"
                }
              >
                <div key={index} className="card message-card mb-3">
                  <div className="card-header d-flex flex-wrap w-100 justify-content-between">
                    <h6 className="mb-0">{communication.Subject}</h6>
                    <h6 className="mb-0">
                      {convertDate(communication.SubmittedDateTime)}
                    </h6>
                  </div>
                  <div className="card-body">
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: communication.Message,
                      }}
                    ></p>
                    {(
                      communication?.ApplicationCommunicationDocumentAddDTO ||
                      []
                    ).map((doc, index) => (
                      <div className="d-flex">
                        <a
                          href={doc.DocumentPath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={doc.DocumentPath}
                            alt={doc.DocumentName}
                            lazy
                            width={75}
                            height={75}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        <div className="mt-3 card-box">
          <div className="">
            <div className="form-group d-flex flex-column w-100 mb-4">
              <label htmlFor="subject" className="font_semibold">
                Enter Subject
              </label>
              <InputText
                id="subject"
                className="mt-2"
                placeholder="Enter Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

            <div className="form-group mb-4 editor">
              <label htmlFor="remarks" className="font_semibold mb-2">
                Enter Remarks
              </label>
              <Editor
                value={text}
                onTextChange={(e) => setText(e.htmlValue)}
                style={{ height: "120px" }}
              />
            </div>

            <div className="form-group uploader">
              <Tooltip
                target=".custom-choose-btn"
                content="Choose"
                position="bottom"
              />
              <Tooltip
                target=".custom-upload-btn"
                content="Upload"
                position="bottom"
              />
              <Tooltip
                target=".custom-cancel-btn"
                content="Clear"
                position="bottom"
              />

              <FileUpload
                ref={fileUploadRef}
                name="demo[]"
                url="/api/upload"
                multiple
                accept="image/*"
                maxFileSize={1000000000}
                onUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                cancelOptions={cancelOptions}
              />
            </div>

            <div className="text-end mt-4">
              <Button
                label="Cancel"
                disabled={loading}
                onClick={() => {
                  navigate(`/my-application`);
                }}
                className="apply btn rounded-0 mx-2 bg_light dark_outline primary_color font_semibold"
              ></Button>
              <Button
                label="Submit"
                icon="pi pi-arrow-right"
                iconPos="right"
                loading={loading}
                className="apply btn rounded-0 mx-2 bg_dark  secondary_color font_semibold"
                onClick={handleSubmit}
              ></Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddCommunication;
