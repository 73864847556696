import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../config-context";

const Footer = () => {
  const { Config } = useConfig();
  const [aboutLinks, setAboutLinks] = useState([]);
  const [candidateLinks, setCandidateLinks] = useState([]);
  const [employerLinks, setEmployerLinks] = useState([]);

  const getLinksByType = (links, linkType) => {
    return (links || []).filter(({ type }) => type === linkType);
  };

  useEffect(() => {
    setAboutLinks(getLinksByType(Config?.footerLinks, "ABOUT"));
    setCandidateLinks(getLinksByType(Config?.footerLinks, "CANDIDATE"));
    setEmployerLinks(getLinksByType(Config?.footerLinks, "EMPLOYER"));
  }, [Config]);

  return (
    <footer className="bg_dark padding-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-lg-4 col-md-12 d-lg-none d-block mb-4">
                <div className="d-md-flex d-block">
                  <Link className="navbar-brand" to="/">
                    <img
                      src="https://tmrw.com/wp-content/uploads/Logo_Quer_weiss-1.png"
                      alt=""
                      id="footer-logo"
                    />
                  </Link>

                  <div className="ms-md-5 ms-0">
                    <h4 className="font_semibold secondary_color">
                      Building An Internet Of Life<sup>TM</sup>
                    </h4>

                    <div className="d-flex mt-3 social-icons">
                      <h5 className="font_semibold secondary_color">
                        Follow Us:
                      </h5>
                      <Link to="">
                        <i className="pi pi-facebook"></i>
                      </Link>
                      <Link to="">
                        <i className="pi pi-twitter"></i>
                      </Link>
                      <Link to="">
                        <i className="pi pi-linkedin"></i>
                      </Link>
                      <Link to="">
                        <i className="pi pi-instagram"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-4 col-6 mb-4">
                <h4 className="font_semibold secondary_color">About Company</h4>
                <ul className="mt-4">
                  {aboutLinks.map((link) => (
                    <li className="font_regular secondary_color">
                      <Link to={link.path}> {link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 col-6 mb-4">
                <h4 className="font_semibold secondary_color">
                  For Candidate's
                </h4>
                <ul className="mt-4">
                  {candidateLinks.map((link) => (
                    <li className="font_regular secondary_color">
                      <Link to={link.path}> {link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 col-6 mb-4">
                <h4 className="font_semibold secondary_color">
                  For Employer's
                </h4>
                <ul className="mt-4">
                  {employerLinks.map((link) => (
                    <li className="font_regular secondary_color">
                      <Link to={link.path}> {link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-lg-block d-none">
            <Link className="navbar-brand" to="/">
              <img
                src="https://tmrw.com/wp-content/uploads/Logo_Quer_weiss-1.png"
                alt=""
                id="footer-logo"
              />
            </Link>

            <h4 className="font_semibold secondary_color">
              Building An Internet Of Life<sup>TM</sup>
            </h4>

            <div className="d-flex mt-3 social-icons">
              <h5>Follow Us:</h5>
              <Link to="">
                <i className="pi pi-facebook"></i>
              </Link>
              <Link to="">
                <i className="pi pi-twitter"></i>
              </Link>
              <Link to="">
                <i className="pi pi-linkedin"></i>
              </Link>
              <Link to="">
                <i className="pi pi-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
