import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { FormControlType } from "../../shared/enum/form-control-type.enum";
import { decapitalizeKeys, formatDateToISO } from "../../shared/utils/utils";
import { Button } from "primereact/button";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import CustomFormGroup from "../shared/forms/custom-forms";
import { useConfig } from "../../config-context";

const Questionaries = () => {
  const navigate = useNavigate();
  const { applicationNumber } = useParams();
  const { Endpoints } = useConfig();
  const inputClass = "col-12 mb-3";
  const startDate = new Date();
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [allFormValue, setAllFormValue] = useState([]);
  const [error, setError] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [formGroupList, setFormGroupList] = useState([[]]);

  const fetchQuestionList = async () => {
    try {
      const result = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.getApplicationQuestionnaires}/${applicationNumber}`
      );
      const questionList = (result?.Data || []).sort(
        (item) => item?.SequenceNumber
      );
      setQuestionList(result?.Data || []);
      initAllFormValue(questionList);
      generateFormGroupList(questionList);
    } catch (error) {
      setError(error);
    }
  };

  const initAllFormValue = (questionList) => {
    (questionList || []).map((question, index) => {
      let answer = question?.Answer;
      const isDropdown = question?.ApplicationQuestionnaireLineAnswer?.length;

      if (!!answer) {
        if (isDropdown) {
          answer = question?.ApplicationQuestionnaireLineAnswer.find(
            ({ Text }) => Text === answer
          );
        }
        allFormValue.push({
          answer: answer || "",
          index,
          comments: question?.Comments || "",
        });
      }
    });
    if (allFormValue.length) {
      setFormValue(allFormValue[activeQuestionIndex]);
    }
  };

  const updateQuestionnaires = async (payload) => {
    setLoading(true);
    try {
      await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        Endpoints.applicationQuestionnaires,
        HttpMethod.PUT,
        payload
      );
      navigate("/my-application");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const generateFormGroupList = (questionList) => {
    const formGroupList = questionList.map((question) => {
      const isDropdown = !!question?.ApplicationQuestionnaireLineAnswer?.length;
      return [
        {
          label: "Answer",
          placeholder: isDropdown ? "Select Answer" : "Answer",
          propertyName: "answer",
          type: isDropdown ? FormControlType.DROPDOWN : FormControlType.TEXT,
          class: inputClass,
          options: question?.ApplicationQuestionnaireLineAnswer,
          optionLabel: "Text",
          optionValue: "",
        },
        {
          label: "Comment",
          placeholder: "Enter your comment",
          propertyName: `comments`,
          type: FormControlType.TEXTAREA,
          class: inputClass,
        },
      ];
    });
    setFormGroupList(formGroupList);
  };

  const handleSubmit = (value) => {
    setFormValue(value);
  };

  const isLastQuestion = () => {
    return activeQuestionIndex + 1 === questionList.length;
  };

  const isFirstQuestion = () => {
    return activeQuestionIndex === 0;
  };

  const onNextQuestion = () => {
    const indexFormValue = {
      ...formValue,
      index: activeQuestionIndex,
    };
    const alreadyAdded = allFormValue.find(
      ({ index }) => index === activeQuestionIndex
    );
    if (alreadyAdded) {
      allFormValue[activeQuestionIndex] = {
        ...formValue,
        index: activeQuestionIndex,
      };
    } else {
      allFormValue.push(indexFormValue);
    }

    const updatedIndex = activeQuestionIndex + 1;
    const nextQuestionFormValue = allFormValue.find(
      ({ index }) => index === updatedIndex
    );
    setAllFormValue(allFormValue);
    if (nextQuestionFormValue) {
      setFormValue(nextQuestionFormValue);
    } else {
      setFormValue({});
    }
    setActiveQuestionIndex(() => updatedIndex);
  };

  const onBackQuestion = () => {
    const updatedIndex = activeQuestionIndex - 1;
    setActiveQuestionIndex(() => updatedIndex);
    const activeFormValue = allFormValue.find(
      ({ index }) => index === updatedIndex
    );
    setFormValue(activeFormValue);
  };

  const onSubmit = () => {
    const indexFormValue = {
      ...formValue,
      index: activeQuestionIndex,
    };
    const alreadyAdded = allFormValue.find(
      ({ index }) => index === activeQuestionIndex
    );
    if (alreadyAdded) {
      allFormValue[activeQuestionIndex] = {
        ...formValue,
        index: activeQuestionIndex,
      };
    } else {
      allFormValue.push(indexFormValue);
    }
    updateQuestionnaires(generatePayload());
  };

  const getAnswer = (answer) => {
    if (!!answer.ERPRecId) {
      return {
        answerErpRecId: answer?.ERPRecId,
        answer: answer?.Text,
      };
    } else {
      return { answer } || "";
    }
  };

  const generatePayload = () => {
    const applicationQuestionnaireLine = questionList.map((question, index) => {
      const object = decapitalizeKeys(question);
      return {
        ...object,
        ...getAnswer(allFormValue[index]?.answer),
        comments: allFormValue[index]?.comments || "",
      };
    });

    return {
      number: 0,
      applicationNumber: 0,
      completed: 0,
      status: 0,
      rowVersion: null,
      isSynced: 0,
      isDeleted: null,
      startDate: formatDateToISO(startDate),
      endDate: formatDateToISO(new Date()),
      dataArea: "",
      erpRecId: questionList[0]?.ApplicationQuestionnaireErpRecId,
      applicationQuestionnaireLine,
    };
  };

  useEffect(() => {
    fetchQuestionList();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Application Questionaries</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header d-flex">
          <Link to="/my-application">
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
          </Link>
          <span className="fs-3 font_semibold">Application Questionaries</span>
        </div>
        {questionList ? (
          <div className="mt-4 card-box">
            <div className="header d-flex justify-content-between">
              <h5>
                Question {activeQuestionIndex + 1} :{" "}
                {questionList[activeQuestionIndex]?.Text}
              </h5>
              <h6>
                {activeQuestionIndex + 1} of {questionList.length}
              </h6>
            </div>
            <div className="form mt-4">
              <CustomFormGroup
                formGroupList={formGroupList[activeQuestionIndex]}
                formValue={formValue}
                setFormValue={handleSubmit}
              ></CustomFormGroup>
            </div>
            <div className="d-flex justify-content-end mt-3">
              {!isFirstQuestion() ? (
                <Button
                  label="Back"
                  icon="pi pi-arrow-left"
                  className="apply btn rounded-0 mx-2 bg_light dark_outline primary_color font_semibold"
                  onClick={onBackQuestion}
                ></Button>
              ) : (
                ""
              )}
              {!isLastQuestion() ? (
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  className="apply btn rounded-0 mx-2 bg_light dark_outline primary_color font_semibold"
                  onClick={onNextQuestion}
                ></Button>
              ) : (
                ""
              )}
              {isLastQuestion() ? (
                <Button
                  label="Submit"
                  className="apply btn rounded-0 mx-2 bg_dark secondary_color font_semibold"
                  loading={loading}
                  onClick={onSubmit}
                ></Button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
    </>
  );
};
export default Questionaries;
