import axios from "axios";
export class SignupHelper {
  constructor(endpoints) {
    this.Endpoints = endpoints;
  }

  tenantName = this.Endpoints.tenantName;
  tokenEndpoint = this.Endpoints.tokenEndPoints;

  async signup(username) {
    const params = new URLSearchParams();
    const helper = new SignupHelper();
    params.append("email", username);

    try {
      const response = await axios.post(helper.tokenEndpoint, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        `Login failed: ${error.response ? error.response.data : error.message}`
      );
    }
  }
}
