import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { FormControlType } from "../../../shared/enum/form-control-type.enum";

const FormControl = ({ control, value, handleValueChange }) => {
  const handleSelectionChange = (event, control) => {
    handleValueChange(event);

    if (control?.onChange) {
      control.onChange(event.value);
    }
  };

  const getCheckboxValue = (value) => {
    if (value) {
      if (typeof value === "string") {
        return !!parseInt(value);
      } else {
        return value;
      }
    } else {
      return false;
    }
  };

  const getInputTemplate = (label, placeholder, propertyName, isRequired) => {
    return (
      <div className="">
        <label htmlFor={propertyName} className="mb-2">
          {label} {""}{" "}
          <span className="text-danger">{isRequired ? "*" : ""}</span>
        </label>
        <InputText
          type="text"
          className={
            isRequired && !value ? "d-block w-100 p-invalid" : "d-block w-100 "
          }
          placeholder={placeholder}
          value={value || ""}
          id={propertyName}
          name={propertyName}
          onChange={handleValueChange}
        />
      </div>
    );
  };

  const getTextareaTemplate = (
    label,
    placeholder,
    propertyName,
    isRequired
  ) => {
    return (
      <div className="">
        <label htmlFor={propertyName} className="mb-2">
          {label} {""}{" "}
          <span className="text-danger">{isRequired ? "*" : ""}</span>
        </label>
        <InputTextarea
          className={
            isRequired && !value ? "d-block w-100 p-invalid" : "d-block w-100 "
          }
          rows={4}
          placeholder={placeholder}
          value={value || ""}
          id={propertyName}
          name={propertyName}
          onChange={handleValueChange}
        />
      </div>
    );
  };

  const getDropdownTemplate = (
    label,
    placeholder,
    propertyName,
    options,
    optionLabel,
    optionValue,
    isRequired
  ) => {
    return (
      <div className="">
        <label htmlFor={propertyName} className="mb-2">
          {label} {""}{" "}
          <span className="text-danger">{isRequired ? "*" : ""}</span>
        </label>
        <Dropdown
          showClear
          filter
          className={isRequired && !value ? "w-100 p-invalid" : "w-100 "}
          optionValue={
            optionValue !== null && optionValue !== undefined
              ? optionValue
              : "ERPRecId"
          }
          optionLabel={optionLabel || "Code"}
          placeholder={placeholder}
          id={propertyName}
          name={propertyName}
          options={options}
          value={value}
          onChange={(event) => {
            handleSelectionChange(event, control);
          }}
        />
      </div>
    );
  };

  const getDatePickerTemplate = (label, propertyName, isRequired) => {
    return (
      <div className="">
        <label htmlFor={propertyName} className="mb-2">
          {label} {""}{" "}
          <span className="text-danger">{isRequired ? "*" : ""}</span>
        </label>
        <Calendar
          showIcon
          placeholder="mm/dd/yyyy"
          className={isRequired && !value ? "w-100 p-invalid" : "w-100 "}
          id={propertyName}
          name={propertyName}
          value={value ? new Date(value) : ""}
          onChange={handleValueChange}
        />
      </div>
    );
  };

  const getNumberTemplate = (label, placeholder, propertyName, isRequired) => {
    return (
      <div className="">
        <label htmlFor={propertyName} className="mb-2">
          {label} {""}{" "}
          <span className="text-danger">{isRequired ? "*" : ""}</span>
        </label>
        <InputText
          keyfilter="int"
          className={
            isRequired && !value ? "d-block w-100 p-invalid" : "d-block w-100 "
          }
          placeholder={placeholder}
          value={value}
          id={propertyName}
          name={propertyName}
          onChange={handleValueChange}
        />
      </div>
    );
  };

  const getCheckboxTemplate = (label, propertyName, isRequired) => {
    return (
      <div className="d-flex align-items-center">
        <Checkbox
          className={isRequired && !value ? "p-invalid" : ""}
          inputId={propertyName}
          name={propertyName}
          onChange={handleValueChange}
          checked={!!getCheckboxValue(value)}
        />
        <label htmlFor={propertyName} className="ms-2">
          {label} {""}{" "}
          <span className="text-danger">{isRequired ? "*" : ""}</span>
        </label>
      </div>
    );
  };

  const generateFormControl = () => {
    switch (control?.type) {
      case FormControlType.TEXT:
        return getInputTemplate(
          control?.label,
          control?.placeholder,
          control?.propertyName,
          control?.isRequired
        );
      case FormControlType.TEXTAREA:
        return getTextareaTemplate(
          control?.label,
          control?.placeholder,
          control?.propertyName,
          control?.isRequired
        );
      case FormControlType.DROPDOWN:
        return getDropdownTemplate(
          control?.label,
          control?.placeholder,
          control?.propertyName,
          control?.options,
          control?.optionLabel,
          control?.optionValue,
          control?.isRequired
        );
      case FormControlType.DATE:
        return getDatePickerTemplate(
          control?.label,
          control?.propertyName,
          control?.isRequired
        );
      case FormControlType.CHECKBOX:
        return getCheckboxTemplate(
          control?.label,
          control?.propertyName,
          control?.isRequired
        );
      case FormControlType.NUMBER:
        return getNumberTemplate(
          control?.label,
          control?.placeholder,
          control?.propertyName,
          control?.isRequired
        );
      default:
        return getInputTemplate(
          control.label,
          control.placeholder,
          control.propertyName,
          control?.isRequired
        );
    }
  };

  return <>{generateFormControl()}</>;
};

export default FormControl;
