import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Helmet } from "react-helmet";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { convertDate, convertDateTime } from "../../shared/utils/utils";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { useConfig } from "../../config-context";

const AppliedJobs = () => {
  const toast = useRef(null);
  const { Endpoints } = useConfig();
  const [error, setError] = useState(null);
  const [profile, setProfileData] = useState(null);
  const [isGridView, setIsGridView] = useState(true);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState({
    name: "All",
    code: "8",
  });

  const status = [
    { name: "All", code: "8" },
    { name: "Received", code: "0" },
    { name: "Confirmed", code: "1" },
    { name: "Interview", code: "2" },
    { name: "Rejection", code: "3" },
    { name: "Withdraw", code: "4" },
    { name: "Employed", code: "5" },
  ];

  const getStatusName = (code) => {
    const statusMapping = {
      8: "All",
      0: "Received",
      1: "Confirmed",
      2: "Interview",
      3: "Rejection",
      4: "Withdraw",
      5: "Employed",
    };

    return statusMapping[code.toString()] || "Unknown Status";
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };

  const toggleGridView = () => {
    setIsGridView(true);
  };

  const toggleTableView = () => {
    setIsGridView(false);
  };

  const fetchJobs = async () => {
    setLoading(true);
    setJobs([]);
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.appliedJobs}/${selectedStatus.code}/${profile.Number}`
      );
      if (response?.Data?.length) {
        const filteredData = response.Data.filter((item) => {
          return (
            selectedStatus.code === "8" ||
            item.Status.toString() === selectedStatus.code.toString()
          );
        });

        setJobs(
          filteredData.map((item) => ({
            id: item?.JobERPRecId,
            code: item?.Code,
            description: item?.Description,
            location: item?.Locations,
            longDescription: item?.LongDescription,
            openingDate: item?.OpeningDate,
            closingDate: item?.ClosingDate,
            appliedDate: item?.AppliedDate,
            applicationNumber: item?.ApplicationNumber,
            obj_applicationQuestionnaire: item?.obj_applicationQuestionnaire,
            checkListPendingCount: item?.CheckListPendingCount
              ? parseInt(item?.CheckListPendingCount)
              : 0,
            status: item?.Status,
            readableStatus: getStatusName(item?.ApplicationStatus),
          }))
        );
      }
    } catch (error) {
      setError("Failed to fetch jobs");
    } finally {
      setLoading(false);
    }
  };

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  const filteredJobs = jobs.filter((job) => {
    return (
      job.code
        .toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      job.description
        .toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      job.location
        .toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase())
    );
  });

  useEffect(() => {
    getProfileData();
  }, [Endpoints]);

  useEffect(() => {
    if (profile) {
      fetchJobs();
    }
  }, [profile, selectedStatus]);

  return (
    <>
      <Toast ref={toast} />

      <Helmet>
        <meta charSet="utf-8" />
        <title> My Application</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header">
          <h2>My Application</h2>
        </div>

        <div className="mt-4 card-box">
          <div className="d-flex align-items-center w-100">
            <InputText
              type="text"
              value={globalFilterValue}
              onChange={(e) => setGlobalFilterValue(e.target.value)}
              placeholder="Search jobs..."
              className="w-100 me-2"
            />
            <Dropdown
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.value);
              }}
              options={status}
              optionLabel="name"
              placeholder="Filter By Status"
              className="me-2 w-25"
            />
          </div>
        </div>

        <div className="mt-4 card-box">
          <div className="w-100 d-flex justify-content-between">
            <h3>Search Results</h3>
            <div className="">
              <i
                role="button"
                className={`fs-5 ms-2 p-2 pi pi-microsoft ${
                  isGridView ? "bg_dark secondary_color" : "text_dark"
                }`}
                onClick={toggleGridView}
              ></i>

              <i
                role="button"
                className={`pi pi-list fs-5 ms-3 p-2 ${
                  isGridView ? "text_dark" : "bg_dark secondary_color"
                }`}
                onClick={toggleTableView}
              ></i>
            </div>
          </div>

          <div className="row mt-3">
            {isGridView ? (
              (filteredJobs || []).length > 0 ? (
                filteredJobs.map((job, index) => (
                  <div className="col-lg-4 col-md-6 mb-3">
                    <Link to={`/job-details/${job?.id}/${profile?.Email}`}>
                      <div className="trending-card">
                        <div className="trending-card-body">
                          <h4 className="font_bold">{job.code}</h4>
                          <h5>{job.location}</h5>
                          <p className="font_regular primary_light_color">
                            {job.description}
                          </p>
                          <div className="d-flex w-100 mb-3">
                            <div className="input-bg-light w-50 me-2 small-text  font_semibold small py-1 px-1 rounded">
                              Applied: {convertDate(job.appliedDate)}
                            </div>

                            <div className="input-bg-light w-50 ms-2 small-text float-end font_semibold small py-1 px-1 rounded">
                              Status: {job.readableStatus}{" "}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <button className="bookmark btn me-2 communication">
                                <Link
                                  to={`/add-communication/${job.applicationNumber}`}
                                >
                                  <i className="pi pi-comments"></i>
                                </Link>
                              </button>
                              <Tooltip
                                target=".communication"
                                content="Communication"
                                position="bottom"
                              />
                              <Tooltip
                                target=".checklist"
                                content="Checklist"
                                position="bottom"
                              />
                              <Tooltip
                                target=".questionaire"
                                content="Questionaire"
                                position="bottom"
                              />
                              {!!job?.checkListPendingCount ? (
                                <button className="bookmark btn me-2 checklist">
                                  <Link
                                    to={`/checklist/${job.applicationNumber}`}
                                  >
                                    <i className="pi pi-list"></i>
                                  </Link>
                                </button>
                              ) : (
                                ""
                              )}
                              {!!job?.obj_applicationQuestionnaire ? (
                                <button className="bookmark btn me-2 questionaire">
                                  <Link
                                    to={`/questionaries/${job.applicationNumber}`}
                                  >
                                    <i className="pi pi-question-circle"></i>
                                  </Link>
                                </button>
                              ) : (
                                ""
                              )}
                            </div>

                            {/* <button className="apply btn font_semibold">
                              <Link
                                to={`/job-details/${job?.id}/${profile?.Email}`}
                              >
                                View Job{" "}
                                <span className="ms-3">
                                  <i className="pi pi-arrow-right"></i>
                                </span>
                              </Link>
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <h5 className="mt-3">No Results Found</h5>
              )
            ) : (
              <div className="table-responsive">
                <DataTable
                  className=""
                  showGridlines
                  value={filteredJobs}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column field="code" header="Position" sortable></Column>

                  <Column
                    field="description"
                    header="Description"
                    sortable
                  ></Column>
                  <Column
                    field="appliedDate"
                    header="Applied Date"
                    sortable
                    body={(rowData) =>
                      rowData?.appliedDate
                        ? convertDateTime(rowData?.appliedDate)
                        : ""
                    }
                  ></Column>
                  <Column
                    field="readableStatus"
                    header="Status"
                    sortable
                  ></Column>
                  <Column
                    field="action"
                    header="Action"
                    style={{ width: "150px" }}
                    body={(rowData) => (
                      <div className="d-flex">
                        <button className="bookmark border btn me-2">
                          <Link
                            to={`/add-communication/${rowData.applicationNumber}`}
                          >
                            <i className="pi pi-comments"></i>
                          </Link>
                        </button>
                        {!!rowData?.checkListPendingCount ? (
                          <button className="bookmark border btn me-2">
                            <Link
                              to={`/checklist/${rowData.applicationNumber}`}
                            >
                              <i className="pi pi-list"></i>
                            </Link>
                          </button>
                        ) : (
                          ""
                        )}
                        {!!rowData?.obj_applicationQuestionnaire ? (
                          <button className="bookmark border btn me-2">
                            <Link
                              to={`/questionaries/${rowData.applicationNumber}`}
                            >
                              <i className="pi pi-question-circle"></i>
                            </Link>
                          </button>
                        ) : (
                          ""
                        )}
                        <button className="btn d-inline border mx-1">
                          <Link
                            to={`/job-details/${rowData.id}/${profile?.Email}`}
                          >
                            <i className="pi pi-arrow-right"></i>
                          </Link>
                        </button>
                      </div>
                    )}
                  />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default AppliedJobs;
