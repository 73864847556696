import axios from 'axios';
import { SessionStorageKey } from '../enum/session-storage-key.enum';

const tenantName = 'steeplesb2c';
const clientId = 'c414679a-4c9f-492c-80b4-55de01934e33';
const scope = 'openid offline_access https://steeplesb2c.onmicrosoft.com/api/user_impersonation';

const tokenEndpoint = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/b2c_1_signupnsignin/oauth2/v2.0/token`;

export const signIn = async (username, password) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'password');
  params.append('client_id', clientId);
  params.append('scope', scope);
  params.append('email', username);
  params.append('password', password);


  try {
    const response = await axios.post(tokenEndpoint, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(`Login failed: ${error.response ? error.response.data : error.message}`);
  }
};

export const isAuthenticated = () => {
  return !!getAccountInfo();
}

export const getAccountInfo = () => {
  const storage = sessionStorage.getItem(SessionStorageKey.ACCOUNT_KEY);
  let accountInfo;
  let accountKey;
  if(storage) {
    accountKey = JSON.parse(storage)[0];
  }
  if(accountKey) {
    accountInfo = JSON.parse(sessionStorage.getItem(accountKey) || "");
  }
  return accountInfo;
}
