import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useConfig } from "../../config-context";

const Header = () => {
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState("");
  const { Config } = useConfig();
  const [links, setLinks] = useState([]);

  useEffect(() => {
    setLinks(Config?.headerLinks || []);
  }, [Config]);

  const handleLogin = async () => {
    try {
      let { idToken } = await instance.loginRedirect();
      setIdToken(idToken);
    } catch (error) {}
  };

  const Logout = async () => {
    try {
      await instance.logoutRedirect();
      setIdToken("");
    } catch (error) {}
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top w-100 bg_dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="https://tmrw.com/wp-content/uploads/Logo_Quer_weiss-1.png"
            alt=""
            id="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#web-navbar"
          aria-controls="web-navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="web-navbar">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {links.map((link) =>
              link.isActive ? (
                <li className="nav-item" key={link.id}>
                  <Link
                    className={`nav-link font_regular secondary_color`}
                    to={link.path}
                  >
                    {link.title}
                  </Link>
                </li>
              ) : (
                ""
              )
            )}
            <li className="nav-item nav-space"></li>
            <UnauthenticatedTemplate>
              <li className="nav-item">
                <Link
                  className="nav-link font_semibold secondary_color nav-button btn"
                  onClick={() => handleLogin()}
                >
                  Login
                  <span className="ms-3">
                    <i className="pi pi-arrow-right"></i>
                  </span>
                </Link>
              </li>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
              <li className="nav-item">
                <button
                  className="nav-link font_semibold secondary_color"
                  onClick={() => Logout()}
                >
                  Logout
                </button>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link font_semibold secondary_color nav-button btn"
                  to="/dashboard"
                >
                  Go To Dashboard
                  <span className="ms-3">
                    <i className="pi pi-arrow-right"></i>
                  </span>
                </Link>
              </li>
            </AuthenticatedTemplate>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
