import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { convertDateTime } from "../../shared/utils/utils";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../shared/service/authentication";
import { useMsal } from "@azure/msal-react";

const JobCard = ({ job, profile }) => {
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState("");
  let navigate = useNavigate();

  const handleLogin = async () => {
    try {
      let { idToken } = await instance.loginRedirect();
      setIdToken(idToken);
    } catch (error) {}
  };

  const onViewPosting = (job, profile) => {
    if (isAuthenticated()) {
      navigate(`/job-details/${job?.id}/${profile?.Email}`);
    } else {
      handleLogin();
    }
  };
  return (
    <div className="trending-card h-100">
      <div className="trending-card-body h-100">
        <h4 className="font_bold">{job?.code}</h4>
        <h5>{job?.location || "Location Not Specified"}</h5>
        <p className="font_regular primary_light_color">
          {job?.description || "No Description"}
        </p>

        <div className="d-flex w-100 mb-3">
          <div className="input-bg-light w-50 me-2 small-text  font_semibold small py-1 px-1 rounded">
            Start: {convertDateTime(job?.openingDate)}
          </div>

          <div className="input-bg-light w-50 ms-2 small-text font_semibold small py-1 px-1 rounded">
            End: {convertDateTime(job?.closingDate)}
          </div>
        </div>

        <div className="text-end mt-auto">
          <button
            className="apply btn font_semibold mb-3"
            onClick={() => onViewPosting(job, profile)}
          >
            View Posting{" "}
            <span className="ms-3">
              <Icon.ArrowRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default JobCard;
