import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";

import { useConfig } from "../../config-context";

const Checklist = () => {
  let { applicationNumber } = useParams();
  const { Endpoints } = useConfig();
  const [checklist, setChecklist] = useState(null);

  const fetchChecklist = async () => {
    try {
      const result = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.applicationCheckListByApplication}/${applicationNumber}`
      );
      setChecklist(result?.Data || {});
    } catch (error) {}
  };

  const statusBodyTemplate = (value) => {
    return value?.Completed === "1" ? "Completed" : "Pending";
  };

  const actionBodyTemplate = (value) => {
    return (
      <div className="text-center">
        <Link to={`/checklist/update/${value.Number}`}>
          <i role="button" className="pi pi-pencil"></i>
        </Link>
      </div>
    );
  };

  useEffect(() => {
    fetchChecklist();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Application Checklist </title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header d-flex">
          <Link to="/my-application">
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
          </Link>
          <span className="fs-3 font_semibold">Application Checklist</span>
        </div>
        <div className="mt-3 card-box checklist-box">
          <div className="card-header">
            {checklist ? (
              <h4>{checklist[0]?.JobTitle}</h4>
            ) : (
              <Skeleton height="2rem"></Skeleton>
            )}
          </div>
          <div className="card-body mt-3">
            <DataTable loading={!checklist} showGridlines value={checklist}>
              <Column field="Subject" header="Subject"></Column>
              <Column field="Description" header="Description"></Column>
              <Column
                field="Completed"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                style={{ width: "100px" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </main>
    </>
  );
};

export default Checklist;
