import React from 'react'

const Loader = () => {
  return (
    <div id="preloader">
        <div id="status">
            <div className="overlay-bg">
                <div className="text-bg">
                    <video autoPlay muted playsInline>
                        <source src='/assets/video/logo.mp4' />
                    </video>
                    {/* <img src="https://tmrw.com/wp-content/uploads/Logo_Quer_weiss-1.png" height="30px" width="auto" />  */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Loader;