export class NotificationHelper {
  static setToastRef(toastRef) {
    NotificationHelper.toastRef = toastRef;
  }

  static showSuccess(summary, detail) {    
    NotificationHelper.toastRef.current.show({
      severity: "success",
      summary,
      detail,
      life: 3000,
    });
  }

  static showInfo(summary, detail) {
    NotificationHelper.toastRef.current.show({
      severity: "info",
      summary,
      detail,
      life: 3000,
    });
  }

  static showWarn(summary, detail) {
    NotificationHelper.toastRef.current.show({
      severity: "warn",
      summary,
      detail,
      life: 3000,
    });
  }

  static showError(summary, detail) {
    NotificationHelper.toastRef.current.show({
      severity: "error",
      summary,
      detail,
      life: 3000,
    });
  }
}
