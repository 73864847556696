export const convertDate = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString();
  } else {
    return "";
  }
};

export const convertDateTime = (date) => {
  if (date) {
    const dateString = new Date(date);
    const formattedDate = dateString.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  } else {
    return "";
  }
};

export const getPayloadDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T00:00:00`;
};

export const downloadFile = async (response, fileName) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const decapitalizeFirstLetter = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const decapitalizeKeys = (obj) => {
  const newObj = {};
  for (let key in obj) {
    const decapitalizedKey = decapitalizeFirstLetter(key);
    newObj[decapitalizedKey] = obj[key];
  }
  return newObj;
};

export const formatDateToISO = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
  const timezoneOffset = -date.getTimezoneOffset();
  const timezoneOffsetHours = String(
    Math.floor(Math.abs(timezoneOffset) / 60)
  ).padStart(2, "0");
  const timezoneOffsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(
    2,
    "0"
  );
  const timezoneSign = timezoneOffset >= 0 ? "+" : "-";
  const timezone = `${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`;
};

export const capitalizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => capitalizeKeys(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      const value = obj[key];
      acc[capitalizedKey] = capitalizeKeys(value);
      return acc;
    }, {});
  } else {
    return obj;
  }
}
