import { HttpMethod } from "../enum/http-method.enum";
import { getAccountInfo } from "../service/authentication";
import { convertDate } from "../utils/utils";
import { SubscriberHelper } from "./subscriber.helper";

export class ProfileHelper {
  defaultValue = "N/A";

  constructor(endpoints) {
    this.Endpoints = endpoints;
  }

  static getUserEmail() {
    const accountInfo = getAccountInfo();
    return accountInfo?.username || "";
  }

  static getUserFullName(profile) {
    return `${profile?.FirstName || this.defaultValue} ${
      profile?.LastName || ""
    }`;
  }

  static getFullAdress(profile) {
    const addressList = [
      profile?.Street,
      profile?.State,
      profile?.Country,
      profile?.ZipCode,
    ].filter((value) => !!value);

    if (addressList.length) {
      return `${addressList.join(", ")}`;
    } else {
      return "";
    }
  }

  static getUserDate(profile) {
    if (!!profile?.Dob) {
      return convertDate(profile.Dob);
    } else {
      return "";
    }
  }

  static getUserGender(profile) {
    switch (profile?.Gender) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 3:
        return "Non Specific";
      default:
        return "None";
    }
  }

  static getProfileImage(profile) {
    const defaultImage =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIKcTkARlljahDz7xR5gq-lwY3NSwsYMQdl_AlXfua4Yc2QcQ9QIG38gxtEiMGNAdoEck&usqp=CAU";
    return profile?.ImageURL || defaultImage;
  }

  static getExperience(profile) {
    return profile?.CandidateExperiences || [];
  }

  static getUserNumber(profile) {
    return profile?.Number || [];
  }

  static getEducationSummary(profile) {
    return profile?.CandidateEducations || [];
  }

  static getSkillsSummary(profile) {
    return profile?.CandidateSkills || [];
  }

  static getCertificates(profile) {
    return profile?.CandidateCertificateTypes || [];
  }

  static isPersonalInfoAdded(profile) {
    return (
      !!profile?.FirstName &&
      !!profile?.LastName &&
      !!profile?.Email &&
      !!profile?.ContactNo
    );
  }

  static isResumeAdded(profile) {
    return !!profile?.ResumeURL;
  }

  static isExperienceAdded(profile) {
    return !!(profile?.CandidateExperiences || []).length;
  }

  static isSkillsAdded(profile) {
    return !!(profile?.CandidateSkills || []).length;
  }

  static isCertificatesAdded(profile) {
    return !!(profile?.CandidateCertificateTypes || []).length;
  }

  static isEducationAdded(profile) {
    return !!(profile?.CandidateEducations || []).length;
  }

  static getProfileCompletePercentage(profile) {
    const requiredItems = [
      !!this.isPersonalInfoAdded(profile),
      !!this.isResumeAdded(profile),
      !!this.isExperienceAdded(profile),
      !!this.isSkillsAdded(profile),
      !!this.isCertificatesAdded(profile),
      !!this.isEducationAdded(profile),
    ];

    const totalCount = requiredItems.length;
    const trueCount = requiredItems.filter((value) => value === true).length;
    const falseCount = totalCount - trueCount;

    const truePercentage = ((trueCount / totalCount) * 100).toFixed(0);
    const falsePercentage = ((falseCount / totalCount) * 100).toFixed(0);

    return [truePercentage, falsePercentage];
  }

  async fetchProfileInfo() {
    try {
      const endpoint = this.Endpoints.userProfile;
      const email = ProfileHelper.getUserEmail();
      if (email) {
        return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(`${endpoint}/${email}`, HttpMethod.GET);
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async updateProfileInfo(payload, method) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(this.Endpoints.candidates, method, payload);
    } catch (error) {
      throw error;
    }
  }

  async updateRecuriterProfileInfo(payload, method) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(this.Endpoints.recruiters, method, payload);
    } catch (error) {
      throw error;
    }
  }

  async updateExperience(payload, isUpdate) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        this.Endpoints.candidatesExperience,
        isUpdate ? HttpMethod.PUT : HttpMethod.POST,
        payload
      );
    } catch (error) {
      throw error;
    }
  }

  async updateEducation(payload, isUpdate) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        "Candidates/education",
        isUpdate ? HttpMethod.PUT : HttpMethod.POST,
        payload
      );
    } catch (error) {
      throw error;
    }
  }

  async updateSkill(payload, isUpdate) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        this.Endpoints.candidatesSkill,
        isUpdate ? HttpMethod.PUT : HttpMethod.POST,
        payload
      );
    } catch (error) {
      throw error;
    }
  }

  async updateCertificate(payload, isUpdate) {
    const endpoint = isUpdate
      ? this.Endpoints.candidatesCertificate
      : this.Endpoints.candidatesAddCertificate;

    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        endpoint,
        isUpdate ? HttpMethod.PUT : HttpMethod.POST,
        payload
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteExperience(userId, deleteId) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        `${this.Endpoints.candidatesExperience}/${userId}/${deleteId}`,
        HttpMethod.DELETE
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteEducation(userId, deleteId) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        `${this.Endpoints.candidatesEducation}/${userId}/${deleteId}`,
        HttpMethod.DELETE
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteSkill(userId, deleteId) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        `${this.Endpoints.candidatesSkill}/${userId}/${deleteId}`,
        HttpMethod.DELETE
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteCertificate(userId, deleteId) {
    try {
      return await new SubscriberHelper(this.Endpoints.baseUrl).fetchXHR(
        `${this.Endpoints.candidatesCertificate}/${userId}/${deleteId}`,
        HttpMethod.DELETE
      );
    } catch (error) {
      throw error;
    }
  }
}
