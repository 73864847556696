import { confirmDialog } from "primereact/confirmdialog";

export class DialogHelper {
  static openConfirmationDialog(message, header, onAccept, onReject) {
    confirmDialog({
      message,
      header,
      draggable: false,
      defaultFocus: null,
      accept: onAccept,
      reject: onReject,
    });
  }

  static openDeleteConfirmationDialog(message, header, onAccept) {
    confirmDialog({
      message,
      header,
      defaultFocus: null,
      draggable: false,
      icon: "pi pi-trash",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      accept: onAccept,
      acceptClassName: 'ms-3 p-button-danger',
      rejectClassName: 'p-button-primary',
    });
  }
}
