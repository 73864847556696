import React, { useRef, useEffect} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Toast } from "primereact/toast";
import "./override.scss";
import "./index.scss";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { ConfigProvider, useConfig } from "./config-context";
import { NotificationHelper } from "./shared/helper/notification.helper";

const RootComponent = () => {
  const { MsalConfig, loading } = useConfig();
  const toast = useRef(null);

  useEffect(() => {
    NotificationHelper.setToastRef(toast);
  }, []);

  if (loading) {
    return (
      <div>
        <h6>Loading...</h6>
      </div>
    );
  }
  if (!MsalConfig) {
    return (
      <div>
        <h6>Failed to load Msal Config</h6>
      </div>
    );
  }

  const msalInstance = new PublicClientApplication(MsalConfig);

  return (
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <PrimeReactProvider>
          <Toast ref={toast} />
          <App />
        </PrimeReactProvider>
      </MsalProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider>
    <RootComponent />
  </ConfigProvider>
);

reportWebVitals();
