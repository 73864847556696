import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { NotificationHelper } from "../../shared/helper/notification.helper";
import { useConfig } from "../../config-context";

const AddFeedback = () => {
  const [loading, setLoading] = useState(false);
  const { Endpoints } = useConfig();
  const [candidateNumber, setCandidateNumber] = useState(0);
  const [feedback, setFeedbackValue] = useState(null);
  const [ratingValue, setRatingValueValue] = useState(0);
  const [remarkText, setRemarkText] = useState("");

  const getProfileInfo = async () => {
    try {
      setLoading(true);
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setCandidateNumber(result.Data?.Number);
      setLoading(false);
      const candidateFeedbacks = result.Data?.CandidateFeedbacks;
      if (candidateFeedbacks && (candidateFeedbacks || []).length) {
        const feedback = candidateFeedbacks[0];
        setFeedbackValue({ ...feedback });
        setRatingValueValue(feedback?.Rating ? parseInt(feedback?.Rating) : 0);
        setRemarkText(feedback?.Remarks || "");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (ratingValue === 0) {
      NotificationHelper.showError("Error", "Please select rating");
      return;
    }

    if (!(remarkText || "").trim()) {
      NotificationHelper.showError("Error", "Please Enter Remarks.");
      return;
    }

    try {
      const payload = {
        DataArea: "",
        ERPRecId: 0,
        Number: 0,
        CandidateNumber: candidateNumber,
        Rating: ratingValue,
        Remarks: remarkText,
        CreatedOn: new Date().toISOString(),
        IsSynced: 0,
        IsDeleted: 0,
        RowVersion: null,
      };
      setLoading(true);
      await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(Endpoints.candidatesFeedback, HttpMethod.POST, payload);
      getProfileInfo();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfileInfo();
  }, [Endpoints]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Feedback </title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="mt-3 card-box">
          <div className="">
            <div className="form-group d-flex flex-column w-100 mb-4">
              <label htmlFor="rating" className="font_semibold">
                Rating
              </label>
              <Rating
                className="mt-2"
                disabled={!!feedback}
                value={ratingValue}
                onChange={(e) => setRatingValueValue(e.value)}
                cancel={false}
              />
            </div>

            <div className="form-group mb-4 editor">
              <label htmlFor="remarks" className="font_semibold mb-2">
                Enter Remarks
              </label>
              <InputTextarea
                className="d-block w-100"
                rows={5}
                readOnly={!!feedback}
                placeholder="Enter Feedback Remarks"
                value={remarkText || ""}
                onChange={(e) => setRemarkText(e.target.value)}
              />
            </div>

            {!feedback ? (
              <div className="text-end mt-4">
                <Button
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  label="Submit"
                  disabled={loading}
                  loading={loading}
                  className="apply btn rounded-0 mx-2 bg_dark  secondary_color font_semibold"
                  onClick={() => onSubmit()}
                ></Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default AddFeedback;
