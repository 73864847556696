import React, { useState, useEffect, useRef } from "react";
import Credit from "../shared/Credit";
import PersonalInfoForm from "../shared/forms/profile-forms/personal-info-form";
import RecuriterProfileForm from "../shared/forms/profile-forms/recruiter-profile-form";
import { FileUpload } from "primereact/fileupload";
import { decapitalizeKeys, getPayloadDate } from "../../shared/utils/utils";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { Button } from "primereact/button";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { NotificationHelper } from "../../shared/helper/notification.helper";
import { useConfig } from "../../config-context";

const CandidateProfile = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const { Endpoints } = useConfig();
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValue, setFormValue] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [profile, setProfileData] = useState(null);

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
      navigate("/dashboard");
    } catch (error) {
      setError(error);
    }
  };

  const getFormValue = (value) => {
    setFormValue(value);
  };

  const getCountries = (value) => {
    setCountryList(value);
  };

  const getStates = (value) => {
    setStateList(value);
  };

  const getCities = (value) => {
    setCityList(value);
  };

  const getNationalities = (value) => {
    setNationalityList(value);
  };

  const onImageUpload = (event) => {
    const responseText = event?.xhr?.response || event?.xhr?.responseText;
    setImageData(JSON.parse(responseText || null));
  };

  const onDocumentUpload = (event) => {
    const responseText = event?.xhr?.response || event?.xhr?.responseText;
    setResumeData(JSON.parse(responseText || null));
  };

  const candidatevalidatePayload = (payload) => {
    let isValid = true;
    const requiredFields = [
      { field: "firstName", message: "First Name is mandatory." },
      { field: "contactNo", message: "Contact is mandatory." },
      { field: "nationality", message: "Nationality selection is mandatory." },
      { field: "resumeURL", message: "Resume is mandatory." },
    ];

    requiredFields.forEach((item) => {
      if (!payload[item.field] || payload[item.field].trim() === "") {
        toast.current.show({
          severity: "error",
          summary: "Required Field",
          detail: item.message,
          life: 3000,
        });
        isValid = false;
      }
    });

    return isValid;
  };

  const recruitervalidatePayload = (payload) => {
    let isValid = true;
    const requiredFields = [
      { field: "firstName", message: "First Name is mandatory." },
      { field: "middleName", message: "Middle Name is mandatory." },
      { field: "contactNo", message: "Contact is mandatory." },
      { field: "reference", message: "Reference is mandatory." },
      { field: "dob", message: "DOB is mandatory." },
    ];

    requiredFields.forEach((item) => {
      if (!payload[item.field] || payload[item.field].trim() === "") {
        toast.current.show({
          severity: "error",
          summary: "Required Field",
          detail: item.message,
          life: 3000,
        });
        isValid = false;
      }
    });

    return isValid;
  };

  const updateProfile = async (payload) => {
    if (!candidatevalidatePayload(payload)) {
      return;
    }

    try {
      setLoading(true);
      await new ProfileHelper(Endpoints).updateProfileInfo(
        payload,
        HttpMethod.POST
      );
      navigate("/dashboard");
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateRecruiterProfile = async (payload) => {
    if (!recruitervalidatePayload(payload)) {
      return;
    }

    try {
      setLoading(true);
      await new ProfileHelper(Endpoints).updateRecuriterProfileInfo(
        payload,
        HttpMethod.POST
      );
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onFormSubmit = () => {
    let payload = decapitalizeKeys(formValue);

    const selectedCountry = countryList.find(
      ({ ERPRecId }) => ERPRecId === payload?.countryERPRecId
    );
    const selectedState = stateList.find(
      ({ ERPRecId }) => ERPRecId === payload?.stateERPRecId
    );
    const selectedCity = cityList.find(
      ({ ERPRecId }) => ERPRecId === payload?.cityERPRecId
    );
    const selectedNationality = nationalityList.find(
      ({ ERPRecId }) => ERPRecId === payload?.nationalityId
    );

    payload = {
      ...payload,
      userTypeId: 2,
      dob: payload.dob ? getPayloadDate(payload.dob) : "",
      canRelocate: payload?.canRelocate ? 1 : 0,
      canTravel: payload?.canTravel ? 1 : 0,
      disabled: payload?.disabled ? 1 : 0,
      previousEmployee: payload?.previousEmployee ? 1 : 0,
      email: ProfileHelper.getUserEmail(),
      countryCode: selectedCountry?.Code,
      stateCode: selectedState?.Code,
      cityCode: selectedCity?.Code,
      nationality: selectedNationality?.Code,
      imageURL: imageData?.SFilePath || "",
      resumeURL: resumeData?.SFilePath || "",
      candidateExperiences: [],
      candidateEducations: [],
      candidateSkills: [],
      candidateCertificateTypes: [],
      candidateFeedbacks: [],
      dataArea: "",
      erpRecId: 0,
      generatedDate: null,
      isSynced: 0,
      modifiedDate: null,
      number: 0,
      rowVersion: null,
    };

    updateProfile(payload);
  };

  const onRecuriterFormSubmit = () => {
    let payload = decapitalizeKeys(formValue);

    const selectedCountry = countryList.find(
      ({ ERPRecId }) => ERPRecId === payload?.countryERPRecId
    );
    const selectedState = stateList.find(
      ({ ERPRecId }) => ERPRecId === payload?.stateERPRecId
    );
    const selectedCity = cityList.find(
      ({ ERPRecId }) => ERPRecId === payload?.cityERPRecId
    );
    const selectedNationality = nationalityList.find(
      ({ ERPRecId }) => ERPRecId === payload?.nationalityId
    );
    const currentISODate = new Date().toISOString();

    payload = {
      ...payload,
      dob: payload.dob ? getPayloadDate(payload.dob) : "",
      email: ProfileHelper.getUserEmail(),
      countryCode: selectedCountry?.Code,
      stateCode: selectedState?.Code,
      cityCode: selectedCity?.Code,
      nationality: selectedNationality?.Code,
      imageURL: imageData?.SFilePath,
      dataArea: "",
      erpRecId: 0,
      generatedDate: currentISODate,
      isSynced: 0,
      modifiedDate: currentISODate,
      number: 0,
      rowVersion: null,
      userTypeId: 3,
      recruiterOrganizationNumber: payload.recruiterOrganizationNumber
        ? payload.recruiterOrganizationNumber
        : 0,
    };

    updateRecruiterProfile(payload);
  };

  useEffect(() => {
    NotificationHelper.setToastRef(toast);
    getProfileData();
  }, [Endpoints]);

  return (
    <>
      <Toast ref={toast} />
      <div
        className="d-flex flex-column w-100"
        id="candidateProfile"
        style={{ height: "100vh", maxHeight: "100vh" }}
      >
        <div className="py-3 px-3">
          <h3 className="mb-0">Complete your profile to continue</h3>
        </div>

        <div className="p-3">
          <div className="flex align-items-center justify-content-center">
            <h5 className="mr-3 mb-3">Are you a recruiter?</h5>
            <Button
              label="Yes"
              className={`mx-2 ${
                isRecruiter ? "p-button-success" : "p-button-outlined"
              }`}
              onClick={() => setIsRecruiter(true)}
            />
            <Button
              label="No"
              className={`${
                !isRecruiter ? "p-button-danger" : "p-button-outlined"
              }`}
              onClick={() => setIsRecruiter(false)}
            />
          </div>
        </div>

        {isRecruiter ? (
          <>
            <div className="form-group h-100" style={{ overflow: "auto" }}>
              <div className="row m-0 mt-3 p-0">
                <RecuriterProfileForm
                  isEditMode={false}
                  getFormValue={getFormValue}
                  getCountries={getCountries}
                  getStates={getStates}
                  getCities={getCities}
                  getNationalities={getNationalities}
                  formInputClass={"col-lg-3 col-md-6 col-12 mb-3"}
                  formCheckboxClass={"col-lg-3 col-md-6 col-12 mb-3"}
                ></RecuriterProfileForm>

                <div className="d-flex flex-wrap mt-3">
                  <FileUpload
                    auto
                    mode="basic"
                    className="me-3"
                    chooseLabel={
                      imageData?.SOrgFileName || "Upload Profile Image"
                    }
                    chooseOptions={{
                      icon: imageData?.SOrgFileName
                        ? "pi pi-check-circle"
                        : "pi pi-upload",
                      className: imageData?.SOrgFileName
                        ? "p-button-success"
                        : "p-button-primary",
                    }}
                    url={`${Endpoints.baseUrl}/${Endpoints.uploadPhoto}`}
                    accept="image/png, image/jpeg, image/jpg"
                    onUpload={(response) => onImageUpload(response)}
                  />
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="text-end mt-3 mb-2">
                <Button
                  loading={loading}
                  iconPos="right"
                  label="Reset"
                  className="btn rounded py-2 px-3 me-3 bg_light dark_outline primary_color font_semibold"
                ></Button>
                <Button
                  loading={loading}
                  iconPos="right"
                  label="Create Profile"
                  icon="pi pi-arrow-right"
                  className="btn rounded py-2 px-3 me-3 bg_dark secondary_color font_semibold"
                  onClick={onRecuriterFormSubmit}
                ></Button>
              </div>

              <Credit />
            </div>
          </>
        ) : (
          <>
            <div className="form-group h-100" style={{ overflow: "auto" }}>
              <div className="row m-0 mt-3 p-0">
                <PersonalInfoForm
                  getFormValue={getFormValue}
                  getCountries={getCountries}
                  getStates={getStates}
                  getCities={getCities}
                  getNationalities={getNationalities}
                  formInputClass={"col-lg-3 col-md-6 col-12 mb-3"}
                  formCheckboxClass={"col-lg-3 col-md-6 col-12 mb-3"}
                ></PersonalInfoForm>

                <div className="d-flex flex-wrap mt-3">
                  <FileUpload
                    auto
                    mode="basic"
                    className="me-3"
                    chooseLabel={
                      imageData?.SOrgFileName || "Upload Profile Image"
                    }
                    chooseOptions={{
                      icon: imageData?.SOrgFileName
                        ? "pi pi-check-circle"
                        : "pi pi-upload",
                      className: imageData?.SOrgFileName
                        ? "p-button-success"
                        : "p-button-primary",
                    }}
                    url={`${Endpoints.baseUrl}/${Endpoints.uploadPhoto}`}
                    accept="image/png, image/jpeg, image/jpg"
                    onUpload={(response) => onImageUpload(response)}
                  />
                  <FileUpload
                    auto
                    mode="basic"
                    chooseLabel={resumeData?.SOrgFileName || "Upload Resume"}
                    chooseOptions={{
                      icon: resumeData?.SOrgFileName
                        ? "pi pi-check-circle"
                        : "pi pi-upload",
                      className: resumeData?.SOrgFileName
                        ? "p-button-success"
                        : "p-button-primary",
                    }}
                    url={`${Endpoints.baseUrl}/${Endpoints.uploadResume}`}
                    accept=".pdf, .doc, .docx"
                    onUpload={(response) => onDocumentUpload(response)}
                  />
                </div>
              </div>
            </div>

            <div className="footer">
              <div className="text-end mt-3 mb-2">
                <Button
                  loading={loading}
                  iconPos="right"
                  label="Reset"
                  className="btn rounded py-2 px-3 me-3 bg_light dark_outline primary_color font_semibold"
                ></Button>
                <Button
                  loading={loading}
                  iconPos="right"
                  label="Create Profile"
                  icon="pi pi-arrow-right"
                  className="btn rounded py-2 px-3 me-3 bg_dark secondary_color font_semibold"
                  onClick={onFormSubmit}
                ></Button>
              </div>

              <Credit />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CandidateProfile;
