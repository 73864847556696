import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "../shared/Loader";
import Banner from "./Banner";
import TredingJobs from "./TrendingJobs";
import HiringProcess from "./HiringProcess";
import Feedback from "./Feedback";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { useConfig } from "../../config-context";

const Home = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const { Endpoints } = useConfig();
  const [profile, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);

    getProfileData();
  }, [Endpoints]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The TMRW Foundation</title>
        <meta name="description" content="" />
      </Helmet>
      <>
        {screenLoading ? (
          <Loader />
        ) : (
          <>
            <Banner />

            <TredingJobs profile={profile} />

            <HiringProcess />

            <Feedback />
          </>
        )}
      </>
    </>
  );
};

export default Home;
