import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Toast } from "primereact/toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { FileUpload } from "primereact/fileupload";
import * as XLSX from "xlsx";
import { ExcelSheetNames } from "../../shared/enum/excel-sheet-name.enum";
import { useConfig } from "../../config-context";

const AddCandidate = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const { Endpoints } = useConfig();
  let { recruiterNumber } = useParams();

  const [formJsonData, setFormJsonData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileSelect = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        convertExcelToJson(data);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleDownload = () => {
    setLoading(true);
    const fileUrl = "/assets/Candidate-List-Excel.xlsx";
    const fileName = "Candidate_List.xlsx";

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    setLoading(false);
  };

  const convertExcelToJson = (data) => {
    const workbook = XLSX.read(data, { type: "binary" });
    let formData = [];

    (workbook.SheetNames || []).map((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      let jsonValues = XLSX.utils.sheet_to_json(worksheet);

      if (sheetName === ExcelSheetNames.BasicDetails) {
        (jsonValues || []).map((value) => {
          value['RecruiterNumber'] = parseInt(recruiterNumber || 0);
          formData = [...formData, value];
        });
      } else {
        (jsonValues || []).map((value) => {
          const index = (formData || []).findIndex(
            (candidate) => candidate?.Email === value?.Email
          );
          if (index > -1) {
            formData[index][sheetName] = [
              ...(formData[index][sheetName] || []),
              value,
            ];
          }
        });
      }
    });

    setFormJsonData(JSON.stringify(formData, null, 2));
  };

  const handleSubmit = async () => {
    if (formJsonData && formJsonData?.length) {
      const formData = JSON.parse(formJsonData);
      setLoading(true);
      try {
        await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
          Endpoints.addCandidateByRecruiter,
          HttpMethod.POST,
          formData
        );
        navigate(`/candidate-list`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Candidate</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <main className="main">
        <div className="page-header d-flex">
          <Link to="/candidate-list">
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
          </Link>
          <h2>Add Candidate</h2>
        </div>

        <div className="row my-4">
          <div className="col-lg-8">
            <h5>
              <b>Important Instructions for Uploading Candidate List</b>
            </h5>
            <p>
              Please ensure you upload the candidate list using the designated
              Excel file format provided below. This helps maintain data
              integrity and ensures seamless integration with our system.
            </p>
            <p>
              Also while inserting your data please remove the dummy data in it.
            </p>
            <p>
              *Note:-{" "}
              <i>Please Do Not Repeat The Candidate Data In THe Excel File.</i>
            </p>
            <Button
              label=" Download Excel File"
              disabled={loading}
              icon="pi pi-download"
              className="apply btn rounded-0 mx-2 bg_light dark_outline primary_color font_semibold"
              onClick={handleDownload}
            ></Button>
          </div>
        </div>

        <div className="mt-4 card-box">
          <div className="form-group mb-2">
            <FileUpload
              auto
              chooseLabel="Choose Excel File"
              chooseOptions={{
                icon: "pi pi-upload",
              }}
              customUpload={true}
              accept=".xls,.xlsx"
              emptyTemplate={
                <p className="m-0">Drag and drop file here to upload.</p>
              }
              onSelect={(event) => handleFileSelect(event.files[0])}
              onRemove={() => setFormJsonData([])}
              onClear={() => setFormJsonData([])}
            />
          </div>
          <div className="text-end mt-4">
            <Button
              label="Cancel"
              disabled={loading}
              onClick={() => {
                navigate(`/candidate-list`);
              }}
              className="apply btn rounded-0 mx-2 bg_light dark_outline primary_color font_semibold"
            ></Button>
            <Button
              label="Submit"
              icon="pi pi-arrow-right"
              iconPos="right"
              disabled={!formJsonData?.length || loading}
              loading={loading}
              className="apply btn rounded-0 mx-2 bg_dark secondary_color font_semibold"
              onClick={handleSubmit}
            ></Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddCandidate;
