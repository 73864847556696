import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [idToken, setIdToken] = useState("");
  const { instance, accounts } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect().catch((e) => {});
  };

  const Logout = async () => {
    try {
      await instance.logoutPopup();
      setIdToken("");
    } catch (error) {}
  };
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Candidate Login</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-md-7 col-12 d-flex justify-content-center">
            <div className="login-forms text-center">
              <div
                className="py-5 text-center"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <img src="/assets/images/logo-icon.svg" alt="" id="logo-icon" />

                <div>
                  <div className="section-title-light my-4">
                    <h3>
                      <span className="font_semibold primary_color">SIGN </span>
                      <span className="font_semibold primary_light_color">
                        IN
                      </span>
                    </h3>
                  </div>

                  <div className="login-social-icon">
                    <span className="mx-3">
                      <i className="fs-3 pi pi-facebook"></i>
                    </span>
                    <span className="mx-3">
                      <i className="fs-3 pi pi-google"></i>
                    </span>
                    <span className="mx-3">
                      <i className="fs-3 pi pi-linkedin"></i>
                    </span>
                  </div>

                  <div className="my-4">
                    <h6 className="d-flex flex-row">OR</h6>
                  </div>

                  <form className="text-start" onSubmit={handleLogin}>
                    <div className="mb-3">
                      <label for="email" className="form-label font_regular">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label for="password" className="form-label font_regular">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn bg_dark secondary_color w-100"
                    >
                      SIGN IN
                    </button>
                    <button
                      type="submit"
                      className="btn primary_light_color font_semibold w-100 mt-2"
                    >
                      Forget Password ?
                    </button>
                  </form>

                  <button
                    onClick={() => handleLogin()}
                    className="btn bg_dark secondary_color w-100"
                  >
                    SIGN IN
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-5 col-12 h-auto p-0 m-0">
            <div
              className="bg-image-login h-100 position-relative"
              style={{
                background:
                  'linear-gradient(to bottom,rgba(0, 0, 0, 0.52),rgba(0, 0, 0, 0.73)),url("https://images.pexels.com/photos/1181325/pexels-photo-1181325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
              }}
            >
              <div className="position-absolute top-50 start-50 translate-middle align-items-center w-100 px-5">
                <div data-aos="fade-right">
                  <div className="section-title-dark my-4">
                    <h3 className="my-4">
                      <span className="secondary_color font_semibold fs-1">
                        Welcome Back !
                      </span>
                    </h3>
                    <p className="secondary_color font_regular my-3">
                      To Keep Connected with us please login into your account
                    </p>
                  </div>

                  <button
                    type="submit"
                    className="btn light_outline secondary_color mb-3 w-100 font_regular"
                  >
                    <Link to="/register">SIGN UP</Link>
                  </button>

                  <button
                    type="submit"
                    className="btn w-100 mt-2 bg_light primary_color font_regular"
                  >
                    LOGIN AS RECRUITER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
