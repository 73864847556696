import React, { createContext, useState, useEffect, useContext } from "react";

const ConfigContext = createContext();

export const useConfig = () => {
  return useContext(ConfigContext);
};

export const ConfigProvider = ({ children }) => {
  const [Endpoints, setApiEndpoints] = useState(null);
  const [Config, setConfig] = useState(null);
  const [MsalConfig, setMsalConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const { endpoints, config, msalConfig } = await response.json();
        setApiEndpoints(endpoints);
        setConfig(config);
        setMsalConfig(msalConfig);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ Endpoints, Config, MsalConfig, loading }}>
      {children}
    </ConfigContext.Provider>
  );
};
