import React, { useState, useEffect } from "react";
import { FormControlType } from "../../../../shared/enum/form-control-type.enum";
import CustomFormGroup from "../custom-forms";
import { MasterDataHelper } from "../../../../shared/helper/master-data.helper";
import { useConfig } from "../../../../config-context";

const EducationAddForm = ({
  selectedEducation,
  getFormValue,
  formInputClass,
}) => {
  const inputClass = formInputClass || "col-md-6 col-12 mb-3";

  const [formValue, setFormValue] = useState({});
  const [educationList, setEducationList] = useState([]);
  const { Endpoints } = useConfig();

  const getEducationList = async () => {
    const results = await new MasterDataHelper(Endpoints).fetchEductionList();
    setEducationList(results);
  };

  useEffect(() => {
    getEducationList();

    if (selectedEducation) {
      setFormValue({
        ...selectedEducation,
      });
    }
  }, [Endpoints]);

  const formGroupList = [
    {
      label: "Education",
      placeholder: "Select Education",
      propertyName: "EducationERPRecId",
      type: FormControlType.DROPDOWN,
      options: educationList,
      class: formInputClass || "col-12 mb-3",
      isRequired: true,
    },
    {
      label: "Start Date",
      propertyName: "StartDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "End Date",
      propertyName: "EndDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Average Grade",
      placeholder: "Average Grade",
      propertyName: "AvgGrade",
      type: FormControlType.NUMBER,
      class: inputClass,
    },
    {
      label: "Scale",
      placeholder: "Scale",
      propertyName: "Scale",
      type: FormControlType.NUMBER,
      class: inputClass,
    },
  ];

  const handleSubmit = (value) => {
    setFormValue(value);
    getFormValue(value);
  };

  return (
    <>
      <CustomFormGroup
        formGroupList={formGroupList}
        formValue={formValue}
        setFormValue={handleSubmit}
      ></CustomFormGroup>
    </>
  );
};

export default EducationAddForm;
