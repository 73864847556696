import FormControl from "./form-control";

const CustomFormGroup = ({ formGroupList, formValue, setFormValue }) => {
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    let newValue = value;

    if (type === "checkbox") {
      newValue = checked;
    }

    setFormValue((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <>
      {formGroupList.map((control) => (
        <div className={control?.class || ""}>
          <FormControl
            control={control}
            value={formValue[control.propertyName]}
            handleValueChange={handleChange}
          ></FormControl>
        </div>
      ))}
    </>
  );
};

export default CustomFormGroup;
