import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { Autoplay } from "swiper/modules";
import { useConfig } from "../../config-context";

const Feedback = () => {
  const { Endpoints } = useConfig();
  const [feedbackList, setFeedbackList] = useState([]);

  const initFeedbackList = async () => {
    try {
      const result = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        Endpoints.allCandidatesFeedback
      );
      setFeedbackList(result?.Data || []);
    } catch (error) {}
  };

  useEffect(() => {
    initFeedbackList();
  }, []);

  return (
    <section className="testimonials">
      <div className="container-fluid">
        <div className="section-title-light" data-aos="fade-up">
          <h3 className="font_bold">
            <span className="primary_color">FEEDBACK OF </span>
            <span className="primary_ligt_color">USERS</span>
          </h3>
          <p className="primary_color font_regular">
            Our satisfied candidates gives some feedback, please take a look!
          </p>
        </div>

        <Swiper
          loop={true}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          pagination={{
            clickable: true,
          }}
          className="mySwiper py-3"
        >
          {(feedbackList || []).map((feedback, index) => (
            <SwiperSlide key={index} className="h-auto">
              <div className="testimonial-card h-100">
                <div className="text-center">
                  <img
                    className="testimonial-img"
                    src={
                      feedback.Candidate?.ImageURL ||
                      "https://cdn.pixabay.com/photo/2012/04/13/21/07/user-33638_640.png"
                    }
                    alt="user-profile"
                  />
                  <p
                    className="font_regular mt-3 text-uppercase primary_light_color"
                    dangerouslySetInnerHTML={{
                      __html: feedback.Remarks || "No feedback provided",
                    }}
                  ></p>
                  <div className="rating my-3">
                    {[...Array(feedback.Rating || 0).keys()].map((_, i) => (
                      <i key={i} className="pi pi-star-fill mx-2 fs-4"></i>
                    ))}
                    {[...Array(5 - (feedback.Rating || 0)).keys()].map(
                      (_, i) => (
                        <i key={i} className="pi pi-star mx-2 fs-4"></i>
                      )
                    )}
                  </div>
                  <h4 className="font_bold primary_color">
                    {feedback.Candidate?.FirstName || "Unknown"}
                  </h4>
                  <small className="font_bold text-uppercase primary_light_color">
                    {feedback.Candidate?.CurrentJobTitle || "No job title"}
                  </small>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Feedback;
