import React, { useState, useEffect } from "react";
import EducationAddForm from "../shared/forms/profile-forms/education-add-form";
import { Button } from "primereact/button";
import { decapitalizeKeys, getPayloadDate } from "../../shared/utils/utils";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { useConfig } from "../../config-context";

const EditEducation = ({ profile, selectedEducation, onSideBarClose, onSuccess }) => {
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { Endpoints } = useConfig();

  useEffect(() => {
    if (selectedEducation) {
      setFormValue({
        ...selectedEducation,
      });
    }
  }, {});

  const getFormValue = (value) => {
    setFormValue(value);
  };

  const updateEducation = async (payload) => {
    try {
      setLoading(true);
      await new ProfileHelper(Endpoints).updateEducation(payload, !!selectedEducation);
      setLoading(false);
      onSideBarClose(false);
      onSuccess();
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onFormSubmit = () => {
    let payload = decapitalizeKeys(formValue);
    payload = {
      ...payload,
      startDate: payload.startDate? getPayloadDate(new Date(payload.startDate)): "",
      endDate: payload.endDate ? getPayloadDate(new Date(payload.endDate)) : "",
      candidateNumber: profile.Number,
    };
    updateEducation(payload);
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="form-group row m-0" style={{ overflow: "auto" }}>
          <EducationAddForm
            selectedEducation={selectedEducation}
            getFormValue={getFormValue}
          ></EducationAddForm>
        </div>

        <div className="w-100 mt-auto">
          <div className="text-end mt-3">
            <Button
              className="apply btn rounded py-2 px-3 mx-2 bg_light dark_outline primary_color font_semibold"
              label="Cancel"
              onClick={onSideBarClose}
            ></Button>
            <Button
              className="apply btn rounded py-2 px-3 mx-2 bg_dark  secondary_color font_semibold"
              label="Update"
              icon="pi pi-arrow-right"
              iconPos="right"
              loading={loading}
              onClick={() => onFormSubmit(formValue)}
            ></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEducation;
