import React, { useEffect, useState } from "react";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { Skeleton } from "primereact/skeleton";

const NextStep = ({ profile }) => {
  const [isPersonalInfoAdded, setIsPersonalInfoAdded] = useState(false);
  const [isExperienceAdded, setIsExperienceAdded] = useState(false);
  const [isResumeAdded, setIsResumeAdded] = useState(false);
  const [isSkillsAdded, setIsSkillsAdded] = useState(false);

  useEffect(() => {
    if (profile) {
      setIsPersonalInfoAdded(ProfileHelper.isPersonalInfoAdded(profile));
      setIsExperienceAdded(ProfileHelper.isExperienceAdded(profile));
      setIsResumeAdded(ProfileHelper.isResumeAdded(profile));
      setIsSkillsAdded(ProfileHelper.isSkillsAdded(profile));
    }
  }, [profile]);

  return (
    <>
      {profile ? (
        <div className="p-0 min_card_height">
          <div className="p-3 pb-0">
            <div className="title">
              <h5>Your Next Steps</h5>
            </div>
            <div className="w-100">
              <div className="next-step">
                <div className="d-flex justify-content-between py-3">
                  <p
                    className={`font_semibold mb-0 ${
                      isPersonalInfoAdded
                        ? "primary_light_color"
                        : "primary_color"
                    }`}
                  >
                    Add Personal Information
                  </p>
                  <i
                    className={`mt-1 fs-5 pi ${
                      isPersonalInfoAdded
                        ? "text-success pi-check-circle"
                        : "primary_color pi-chevron-right"
                    }`}
                  ></i>
                </div>
                <hr className="m-0" />
              </div>

              <div className="next-step">
                <div className="d-flex justify-content-between py-3">
                  <p
                    className={`font_semibold mb-0 ${
                      isResumeAdded ? "primary_light_color" : "primary_color"
                    }`}
                  >
                    {" "}
                    Upload Resume
                  </p>
                  <i
                    className={`mt-1 fs-5 ${
                      isResumeAdded
                        ? "text-success pi pi-check-circle"
                        : "primary_color pi pi-chevron-right"
                    }`}
                  ></i>
                </div>
                <hr className="m-0" />
              </div>

              <div className="next-step">
                <div className="d-flex justify-content-between py-3">
                  <p
                    className={`font_semibold mb-0 ${
                      isExperienceAdded
                        ? "primary_light_color"
                        : "primary_color"
                    }`}
                  >
                    Add Experience
                  </p>
                  <i
                    className={`mt-1 fs-5 ${
                      isExperienceAdded
                        ? "text-success pi pi-check-circle"
                        : "primary_color pi pi-chevron-right"
                    }`}
                  ></i>
                </div>
                <hr className="m-0" />
              </div>

              <div className="next-step">
                <div className="d-flex justify-content-between py-3">
                  <p
                    className={`font_semibold mb-0 ${
                      isSkillsAdded ? "primary_light_color" : "primary_color"
                    }`}
                  >
                    Add Skills
                  </p>
                  <i
                    className={`mt-1 fs-5 ${
                      isSkillsAdded
                        ? "text-success pi pi-check-circle"
                        : "primary_color pi pi-chevron-right"
                    }`}
                  ></i>
                </div>
                <hr className="m-0" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3 pb-0 min_card_height">
          <div className="mb-3">
            <Skeleton></Skeleton>
          </div>
          <div>
            <Skeleton height="3rem" className="mb-2"></Skeleton>
            <Skeleton height="3rem" className="mb-2"></Skeleton>
            <Skeleton height="3rem" className="mb-2"></Skeleton>
            <Skeleton height="3rem" className="mb-2"></Skeleton>
          </div>
        </div>
      )}
    </>
  );
};

export default NextStep;
