import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { Skeleton } from "primereact/skeleton";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { convertDate } from "../../shared/utils/utils";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DialogHelper } from "../../shared/helper/dialog.helper";
import EditProfile from "./edit-profile";
import EditExperience from "./edit-experience";
import EditEducation from "./edit-education";
import EditSkill from "./edit-skill";
import EditCertificate from "./edit-certificate";
import { useConfig } from "../../config-context";

const FullProfile = () => {
  const [loading, setLoading] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);
  const [sideBarHeader, setSideBarHeader] = useState(false);
  const [sideBarBody, setSideBarBody] = useState(false);
  const [profile, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const { Endpoints } = useConfig();
  const defaultValue = "N/A";

  useEffect(() => {
    getProfileInfo();
  }, [Endpoints]);

  const getProfileInfo = async () => {
    try {
      setLoading(true);
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const getSideBarHeaderTemplate = (title) => (
    <div className="d-flex">
      <span className="me-3  fs-4">
        <button
          type="button"
          className="btn"
          onClick={() => setShowSideBar(false)}
        >
          <i className="pi pi-chevron-left my-auto"></i>
        </button>
      </span>
      <span className="fs-4 font_semibold my-auto">{title}</span>
    </div>
  );

  const onProfileEdit = () => {
    setSideBarHeader(getSideBarHeaderTemplate("Edit Profile Details"));
    setSideBarBody(
      <EditProfile
        profile={profile}
        onSideBarClose={() => setShowSideBar(false)}
        onSuccess={onSuccess}
      />
    );
    setShowSideBar(true);
  };

  const onExperienceEdit = (selectedExperience) => {
    let title = "Add Experience";
    if (selectedExperience) {
      title = "Edit Experience";
    }
    setSideBarHeader(getSideBarHeaderTemplate(title));
    setSideBarBody(
      <EditExperience
        profile={profile}
        selectedExperience={selectedExperience}
        onSideBarClose={() => setShowSideBar(false)}
        onSuccess={onSuccess}
      />
    );
    setShowSideBar(true);
  };

  const onEducationEdit = (selectedEducation) => {
    let title = "Add Education";
    if (selectedEducation) {
      title = "Edit Education";
    }
    setSideBarHeader(getSideBarHeaderTemplate(title));
    setSideBarBody(
      <EditEducation
        profile={profile}
        selectedEducation={selectedEducation}
        onSideBarClose={() => setShowSideBar(false)}
        onSuccess={onSuccess}
      />
    );
    setShowSideBar(true);
  };

  const onSkillEdit = (selectedSkill) => {
    let title = "Add Skill";
    if (selectedSkill) {
      title = "Edit Skill";
    }
    setSideBarHeader(getSideBarHeaderTemplate(title));
    setSideBarBody(
      <EditSkill
        profile={profile}
        selectedSkill={selectedSkill}
        onSideBarClose={() => setShowSideBar(false)}
        onSuccess={onSuccess}
      />
    );
    setShowSideBar(true);
  };

  const onCertificateEdit = (selectedCertificate) => {
    let title = "Add Certificate";
    if (selectedCertificate) {
      title = "Edit Certificate";
    }
    setSideBarHeader(getSideBarHeaderTemplate(title));
    setSideBarBody(
      <EditCertificate
        profile={profile}
        selectedCertificate={selectedCertificate}
        onSideBarClose={() => setShowSideBar(false)}
        onSuccess={onSuccess}
      />
    );
    setShowSideBar(true);
  };

  const openExperienceDeleteDialog = (selectedExperience) => {
    const onAccept = () => {
      onExperienceDelete(selectedExperience);
    };

    DialogHelper.openDeleteConfirmationDialog(
      "Are you sure you want to delete this experience ?",
      "Delete Experience",
      onAccept
    );
  };

  const openEducationDeleteDialog = (selectedEducation) => {
    const onAccept = () => {
      onEducationDelete(selectedEducation);
    };

    DialogHelper.openDeleteConfirmationDialog(
      "Are you sure you want to delete this education ?",
      "Delete Education",
      onAccept
    );
  };

  const openSkillDeleteDialog = (selectedSkill) => {
    const onAccept = () => {
      onSkillDelete(selectedSkill);
    };

    DialogHelper.openDeleteConfirmationDialog(
      "Are you sure you want to delete this Skill ?",
      "Delete Skill",
      onAccept
    );
  };

  const openCertificateDeleteDialog = (selectedCertificate) => {
    const onAccept = () => {
      onCertificateDelete(selectedCertificate);
    };

    DialogHelper.openDeleteConfirmationDialog(
      "Are you sure you want to delete this certificate ?",
      "Delete Certificate",
      onAccept
    );
  };

  const onExperienceDelete = async (selectedExperience) => {
    if (selectedExperience) {
      const { CandidateNumber, Employer } = selectedExperience;
      await new ProfileHelper(Endpoints).deleteExperience(
        CandidateNumber,
        Employer
      );
      getProfileInfo();
    }
  };

  const onEducationDelete = async (selectedEducation) => {
    if (selectedEducation) {
      const { CandidateNumber, EducationERPRecId } = selectedEducation;
      await new ProfileHelper(Endpoints).deleteEducation(
        CandidateNumber,
        EducationERPRecId
      );
      getProfileInfo();
    }
  };

  const onSkillDelete = async (selectedSkill) => {
    if (selectedSkill) {
      const { CandidateNumber, SkillERPRecId } = selectedSkill;
      await new ProfileHelper(Endpoints).deleteSkill(
        CandidateNumber,
        SkillERPRecId
      );
      getProfileInfo();
    }
  };

  const onCertificateDelete = async (selectedCertificate) => {
    if (selectedCertificate) {
      const { CandidateNumber, CertificateTypeERPRecId } = selectedCertificate;
      await new ProfileHelper(Endpoints).deleteCertificate(
        CandidateNumber,
        CertificateTypeERPRecId
      );
      getProfileInfo();
    }
  };

  const onSuccess = () => {
    getProfileInfo();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Profile</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <Sidebar
        className="bg_light primary_colr w-50"
        position="right"
        visible={showSideBar}
        header={sideBarHeader}
        onHide={() => setShowSideBar(false)}
      >
        {sideBarBody}
      </Sidebar>

      <ConfirmDialog />

      <main className="main">
        <div className="page-header">
          <h2>Profile</h2>
        </div>
        <div className="mt-4">
          {loading ? (
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Skeleton height="40rem"></Skeleton>
              </div>
              <div className="col-lg-8 col-md-6">
                <Skeleton height="40rem"></Skeleton>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="card-box h-100">
                  <div className="row image-name-section">
                    <div className="col-4">
                      <img
                        src={ProfileHelper.getProfileImage(profile)}
                        className="rounded profile-image"
                        height={130}
                        width={130}
                        alt=""
                      />
                    </div>
                    <div className="col-8">
                      <h5 className="font_semibold">
                        {ProfileHelper.getUserFullName(profile)}
                      </h5>

                      <p className="primary_light_color lh-1">
                        {profile?.CurrentJobTitle || ""}
                      </p>

                      <Link
                        className="small text-decoration-underline"
                        onClick={() => onProfileEdit()}
                      >
                        Edit Profile
                      </Link>
                    </div>
                  </div>

                  <div className="personal-info-section">
                    <hr className="my-3" />
                    <h4 className="font_semibold">Personal Information</h4>
                    <div className="personal-info">
                      <p className="font_semibold primary_color my-3">
                        <i className="pi pi-inbox"></i> :{" "}
                        <span className="primary_light_color fw-normal">
                          {profile?.Email || defaultValue}
                        </span>
                      </p>

                      <p className="font_semibold primary_color my-3">
                        <i className="pi pi-phone"></i> :{" "}
                        <span className="primary_light_color fw-normal">
                          {profile?.ContactNo || defaultValue}
                        </span>
                      </p>

                      {profile?.AlternateContactNo ? (
                        <p className="font_semibold primary_color my-3">
                          <i className="pi pi-phone"></i> :{" "}
                          <span className="primary_light_color fw-normal">
                            {profile?.AlternateContactNo || defaultValue}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}

                      {ProfileHelper.getFullAdress(profile) ? (
                        <p className="font_semibold primary_color my-3">
                          <i className="pi pi-map-marker"></i> :{" "}
                          <span className="primary_light_color fw-normal">
                            {ProfileHelper.getFullAdress(profile)}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}

                      {ProfileHelper.getUserDate(profile) ? (
                        <p className="font_semibold primary_color my-3">
                          <i className="pi pi-gift"></i> :{" "}
                          <span className="primary_light_color fw-normal">
                            {ProfileHelper.getUserDate(profile)}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}

                      <p className="font_semibold primary_color my-3">
                        <i className="pi pi-key"></i> :{" "}
                        <span className="primary_light_color fw-normal">
                          {ProfileHelper.getUserGender(profile)}
                        </span>
                      </p>
                    </div>
                  </div>

                  {profile?.ResumeURL ? (
                    <div className="documents-section">
                      <hr className="my-3" />
                      <h4 className="font_semibold">Documents</h4>

                      <div className="documents">
                        <div className="d-flex dark_outline rounded p-2 my-2">
                          <span>Resume.docx</span>
                          <span className="ms-auto">
                            <a
                              href={profile.ResumeURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                type="button"
                                className="pi pi-download mx-2"
                              ></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="skills-section">
                  <hr className="my-3" />
                  <h5 className="font_semibold">Skills</h5>
                  <div className="skills">
                    <ul class="list-inline my-3">
                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        HTML
                      </li>
                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        CSS
                      </li>
                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        Javascript
                      </li>

                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        Bootstrap
                      </li>

                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        React
                      </li>

                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        Angular
                      </li>

                      <li class="list-inline-item input-bg-light ms-2 font_semibold small py-1 px-1 rounded px-2 my-2">
                        Node JS
                      </li>
                    </ul>
                  </div>
                </div> */}
                </div>
              </div>

              <div className="col-lg-8 col-md-6">
                <div className="card-box h-100">
                  <div className="experience-section">
                    <div className="title d-flex">
                      <h4 className="font_semibold">Professional Experience</h4>
                      <i
                        className="pi pi-plus my-auto ms-auto"
                        role="button"
                        onClick={() => onExperienceEdit()}
                      >
                        {" "}
                      </i>
                    </div>

                    <div className="experience">
                      {ProfileHelper.getExperience(profile).map(
                        (experience) => (
                          <div className="d-flex my-3">
                            <div className="details">
                              <h5 className="font_regular">
                                {experience?.Employer || defaultValue} -{" "}
                                {experience?.Position || defaultValue}
                              </h5>
                              <p className="mb-0 font_regular primary_light_color">
                                From {convertDate(experience?.StartDate)} To{" "}
                                {convertDate(experience?.EndDate)} - Contact:{" "}
                                {experience?.EmployerContactNo || defaultValue}
                              </p>
                              <p className="mb-0 font_regular primary_light_color">
                                Location:{" "}
                                {experience?.EmployerLocation || defaultValue}{" "}
                              </p>
                            </div>
                            <div className=" ms-auto align-items-center my-auto">
                              <i
                                className="pi pi-file-edit ms-3 fs-4"
                                role="button"
                                onClick={() => onExperienceEdit(experience)}
                              ></i>
                              <i
                                className="pi pi-trash ms-3 fs-4"
                                role="button"
                                onClick={() =>
                                  openExperienceDeleteDialog(experience)
                                }
                              ></i>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="education-section">
                    <hr className="my-4" />
                    <div className="title d-flex">
                      <h4 className="font_semibold">Education Summary</h4>
                      <i
                        className="pi pi-plus my-auto ms-auto"
                        role="button"
                        onClick={() => onEducationEdit()}
                      >
                        {" "}
                      </i>
                    </div>

                    {ProfileHelper.getEducationSummary(profile).map(
                      (education) => (
                        <div className="education">
                          <div className="d-flex my-3">
                            <div>
                              <h5 className="font_regular">
                                {education?.Code || ""}
                              </h5>
                              <p className="font_regular primary_light_color">
                                From {convertDate(education?.StartDate)} -To{" "}
                                {convertDate(education?.EndDate)} - CGPA:{" "}
                                {education?.AvgGrade || defaultValue} - Scale:{" "}
                                {education?.Scale || defaultValue}
                              </p>
                            </div>
                            <div className=" ms-auto align-items-center my-auto">
                              <i
                                className="pi pi-file-edit ms-3 fs-4"
                                role="button"
                                onClick={() => onEducationEdit(education)}
                              ></i>
                              <i
                                className="pi pi-trash ms-3 fs-4"
                                role="button"
                                onClick={() =>
                                  openEducationDeleteDialog(education)
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className="skills-section">
                    <hr className="my-4" />
                    <div className="title d-flex">
                      <h4 className="font_semibold">Skills Summary</h4>
                      <i
                        className="pi pi-plus my-auto ms-auto"
                        role="button"
                        onClick={() => onSkillEdit()}
                      >
                        {" "}
                      </i>
                    </div>

                    <div className="skills">
                      {ProfileHelper.getSkillsSummary(profile).map((skill) => (
                        <div className="d-flex my-3">
                          <div>
                            <h5 className="font_regular">
                              {skill?.SkillCode || defaultValue} -{" "}
                              {skill?.YearOfExperience || defaultValue} Years
                            </h5>
                            <p className="font_regular primary_light_color">
                              {convertDate(skill?.LevelDate)} - Proficient (
                              {skill?.SkillLevelCode || "0"} Star)
                            </p>
                          </div>
                          <div className=" ms-auto align-items-center my-auto">
                            <i
                              className="pi pi-file-edit ms-3 fs-4"
                              role="button"
                              onClick={() => onSkillEdit(skill)}
                            ></i>
                            <i
                              className="pi pi-trash ms-3 fs-4"
                              role="button"
                              onClick={() => openSkillDeleteDialog(skill)}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="certificates-section">
                    <hr className="my-4" />
                    <div className="title d-flex">
                      <h4 className="font_semibold">Certificates</h4>
                      <i
                        className="pi pi-plus my-auto ms-auto"
                        role="button"
                        onClick={() => onCertificateEdit()}
                      >
                        {" "}
                      </i>
                    </div>
                    <div className="certificates">
                      {ProfileHelper.getCertificates(profile).map(
                        (certificate) => (
                          <div className="d-flex my-3">
                            <div>
                              <h5 className="font_regular">
                                {certificate?.Code || ""} - From{" "}
                                {convertDate(certificate?.StartDate)} -To{" "}
                                {convertDate(certificate?.EndDate)}
                              </h5>
                              <p className="font_regular primary_light_color">
                                Renewel :{" "}
                                {certificate?.RenewalRequired === "1"
                                  ? "Yes"
                                  : "No"}
                                <br />
                                Note : {certificate?.Notes}
                              </p>
                            </div>
                            <div className=" ms-auto align-items-center my-auto">
                              <i
                                className="pi pi-file-edit ms-3 fs-4"
                                role="button"
                                onClick={() => onCertificateEdit(certificate)}
                              ></i>
                              <i
                                className="pi pi-trash ms-3 fs-4"
                                role="button"
                                onClick={() =>
                                  openCertificateDeleteDialog(certificate)
                                }
                              ></i>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default FullProfile;
