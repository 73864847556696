import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { Link } from "react-router-dom";

const CompleteProfileCard = ({ profile }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const generateChart = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      datasets: [
        {
          data: ProfileHelper.getProfileCompletePercentage(profile),
          backgroundColor: [
            documentStyle.getPropertyValue("--primary-color"),
            documentStyle.getPropertyValue("--primary-light-color"),
          ],
        },
      ],
    };
    const options = {
      cutout: "70%",
      events: [],
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  };

  useEffect(() => {
    if (profile) {
      generateChart();
    }
  }, [profile]);

  return (
    <>
      {profile ? (
        <div className="dashboard-complete-profile">
          <div className="card p-0 min_card_height">
            <div className="card-header p-3">
              <h5 className="mb-0">Complete Your Profile</h5>
            </div>
            <div className="row card-body m-0 p-3">
              <div className="col-7 d-flex flex-column align-items-start justify-content-between">
                <p className="font_normal primary_light_color small">
                  Completing your profile is the first step towards unlocking
                  numerous opportunities tailored just for you. Your profile is
                  the gateway for employers to discover your skills,
                  experiences, and potential.
                </p>
                <button className="btn bg_light primary_color dark_outline  mb-2">
                  <Link to={"/profile"}>Complete Now</Link>
                </button>
              </div>
              <div className="col-5">
                <div className="chart position-relative">
                  <div className="chart-label">
                    <p className="mb-0">
                      {ProfileHelper.getProfileCompletePercentage(profile)[0]}%
                    </p>
                  </div>
                  <Chart
                    type="doughnut"
                    data={chartData}
                    options={chartOptions}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card p-0 min_card_height">
          <div className="card-header p-3">
            <Skeleton></Skeleton>
          </div>
          <div className="card-body p-3">
            <Skeleton height="12rem"></Skeleton>
          </div>
        </div>
      )}
    </>
  );
};

export default CompleteProfileCard;
