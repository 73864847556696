import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { convertDateTime } from "../../shared/utils/utils";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { Button } from "primereact/button";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { useConfig } from "../../config-context";

const CandidateList = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [profile, setProfileData] = useState(null);
  const { Endpoints } = useConfig();
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState(null);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };

  const initCandidateList = async () => {
    if (profile) {
      try {
        const payload = {
          page_size: 0,
          page_index: 0,
          search_filter_text: "",
          sort_fields: [],
          sort_direction: "",
          filter: "",
          from_date: null,
          to_date: null,
          RecruiterNumber: profile.Number,
        };
        const result = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
          Endpoints.getAllCandidates,
          HttpMethod.POST,
          payload
        );
        setCandidateList(result?.Data || []);
      } catch (error) {
        setError(error);
      }
    }
  };

  const filteredCandidates = (candidateList || []).filter((candidate) => {
    return (
      candidate?.FirstName.toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      candidate?.LastName.toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      candidate?.CurrentJobTitle.toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase()) ||
      candidate?.Email.toString()
        .toLowerCase()
        .includes(globalFilterValue.toLowerCase())
    );
  });

  const deleteSelectedCandidates = async () => {
    const candidateNumbers = selectedCandidates.map(
      (candidate) => candidate.Number
    );

    try {
      await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        Endpoints.deleteCandidates,
        HttpMethod.DELETE,
        candidateNumbers
      );
      initCandidateList();
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [Endpoints]);

  useEffect(() => {
    initFilters();
    initCandidateList();
  }, [profile]);

  return (
    <>
      <Toast ref={toast} />

      <Helmet>
        <meta charSet="utf-8" />
        <title>Candidate List</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header d-flex justify-content-between">
          <h2>Candidate List</h2>

          <Button
            className="apply btn rounded py-2 px-3 mx-2 bg_dark secondary_color font_semibold"
            icon="pi pi-plus"
            label="Add Candidate"
            onClick={() => navigate(`/candidate-list/add/${profile?.Number}`)}
          ></Button>
        </div>

        <div className="mt-4 card-box">
          <div className="d-flex align-items-center w-100">
            <InputText
              type="text"
              value={globalFilterValue}
              onChange={(e) => setGlobalFilterValue(e.target.value)}
              placeholder="Search Candidates..."
              className="w-100 me-2"
            />
          </div>
        </div>

        <div className="mt-4 card-box">
          <div className="page-header d-flex justify-content-between">
            <h2>Candidate List</h2>

            <Button
              className="apply btn rounded py-2 px-3 mx-2 bg_dark secondary_color font_semibold"
              icon="pi pi-trash"
              label="Delete Selected"
              onClick={deleteSelectedCandidates}
              disabled={!selectedCandidates.length}
            ></Button>
          </div>
          <div className="row mt-3">
            <div className="table-responsive">
              <DataTable
                className=""
                showGridlines
                value={filteredCandidates}
                selection={selectedCandidates}
                onSelectionChange={(e) => setSelectedCandidates(e.value)}
                selectionMode="checkbox"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  selectionMode="multiple"
                  style={{ width: "3em" }}
                ></Column>
                <Column field="FirstName" header="FirstName" sortable></Column>
                <Column field="LastName" header="LastName" sortable></Column>
                <Column
                  field="CurrentJobTitle"
                  header="Current Job"
                  sortable
                ></Column>
                <Column field="Email" header="Email" sortable></Column>
                <Column field="ContactNo" header="Contact" sortable></Column>
                <Column
                  field="Dob"
                  header="Birth Date"
                  sortable
                  body={(rowData) => convertDateTime(rowData?.Dob)}
                ></Column>
                <Column
                  field="Gender"
                  header="Gender"
                  sortable
                  body={(rowData) =>
                    rowData.Gender === 1
                      ? "Male"
                      : rowData.Gender === 2
                      ? "Female"
                      : "Non-Specific"
                  }
                ></Column>
                <Column
                  field="action"
                  header="Action"
                  body={(rowData) => (
                    <>
                      <button className="bookmark border btn me-2">
                        <Link to={`/candidate-list/profile/${rowData.Number}`}>
                          <i className="pi pi-info-circle"></i>
                        </Link>
                      </button>
                    </>
                  )}
                  style={{ width: "120px" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CandidateList;
