import React, { useState, useEffect } from "react";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { useMsal } from "@azure/msal-react";
import { Dropdown } from "primereact/dropdown";
import { isAuthenticated } from "../../shared/service/authentication";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../config-context";

const Banner = () => {
  let navigate = useNavigate();
  const [screenLoading, setScreenLoading] = useState(false);
  const { Endpoints } = useConfig();
  const [selectedJob, setSelectedJob] = useState(null);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idToken, setIdToken] = useState("");
  const { instance, accounts } = useMsal();
  const [profile, setProfileData] = useState(null);

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  const selectedJobTemplate = (option, props) => {
    if (option) {
      return (
        <div className="selected-item">
          {option.title} - {option.code}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const jobOptionTemplate = (option) => {
    return (
      <div className="country-item">
        {option.title} - {option.code}
      </div>
    );
  };

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.defaultJobList}/${currentPage}/${pageSize}`,
        HttpMethod.GET
      );
      if (response && Array.isArray(response)) {
        setJobs(
          response.map((item) => ({
            id: item.JobERPRecId,
            code: item.Code,
            description: item.Description,
            location: item.Locations,
            longDescription: item.LongDescription,
            openingDate: item.OpeningDate,
            closingDate: item.ClosingDate,
          }))
        );
      } else {
        setJobs([]);
      }
    } catch (error) {
      setError("Failed to fetch Default Jobs");
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      let { idToken } = await instance.loginRedirect();
      setIdToken(idToken);
    } catch (error) {}
  };

  const onChangeJob = async (e) => {
    setSelectedJob(e.value);
    await onViewPosting(e.value);
  };

  const onViewPosting = (job) => {
    if (isAuthenticated()) {
      navigate(`/job-details/${job?.id}/${profile?.Email}`);
    } else {
      handleLogin();
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [currentPage, pageSize]);

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
    getProfileData();
  }, [Endpoints]);

  return (
    <div className="video bg_dark">
      <div className="video-player">
        <video autoPlay muted playsInline loop>
          <source src="https://www.tmrw.com/wp-content/uploads/internet-of-life.mp4" />
        </video>

        <div className="video-div">
          <div data-aos="fade-up">
            <div className="text-center">
              <h1 className="secondary_color font_bold">
                CHOOSE YOUR BEST LEVEL DREAM CAREER
              </h1>
              <p className="secondary_color font_regular">
                2400 People are daily search in this portal. 100 user added job
                portal!
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-6 text-center">
                <Dropdown
                  value={selectedJob}
                  onChange={onChangeJob}
                  options={jobs}
                  optionLabel="title"
                  placeholder="Select a Job"
                  filter={true}
                  valueTemplate={selectedJobTemplate}
                  itemTemplate={jobOptionTemplate}
                  className="w-100 text-start"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="marquee bg_dark">
        <div className="marquee-content">
          {jobs.map((job, index) => (
            <div className="marquee-tag font_bold">{job?.code}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
