import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import NextStep from "./NextStep";
import ProfileCard from "./ProfileCard";
import MyApplication from "./MyApplication";
import CompleteProfileCard from "./CompleteProfileCard";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { Link, useNavigate } from "react-router-dom";
import RecruiterFullProfile from "../profile/recruiter-profile";
import { Toast } from "primereact/toast";
import { NotificationHelper } from "../../shared/helper/notification.helper";
import { useConfig } from "../../config-context";

const Dashboard = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const { Endpoints } = useConfig();
  const [profile, setProfileData] = useState(null);
  const [availableJobs, setAvailableJobs] = useState([]);
  const [appliedjobs, setAppliedjobs] = useState([]);

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
      if (result?.Data?.Number) {
        fetchAppliedJobs(result.Data.Number);
      }
    } catch (error) {
      if (error.status === 404) {
        navigate("/user-profile");
      }
    }
  };

  const getAvailableJobs = async () => {
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        Endpoints.availableJobCount,
        HttpMethod.GET
      );
      setAvailableJobs(response?.Data || 0);
    } catch (error) {}
  };

  const fetchAppliedJobs = async (number) => {
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.appliedJobs}/8/${number}`,
        HttpMethod.GET
      );
      setAppliedjobs(
        (response?.Data || []).map((item) => ({
          id: item.JobERPRecId,
          code: item.Code,
          description: item.Description,
          location: item.Locations,
          longDescription: item.LongDescription,
          openingDate: item.OpeningDate,
          closingDate: item.ClosingDate,
          appliedDate: item.AppliedDate,
          applicationNumber: item.ApplicationNumber,
          status: item.Status,
          readableStatus: getStatusName(item.ApplicationStatus),
        }))
      );
    } catch (error) {}
  };

  const getStatusName = (code) => {
    const statusMapping = {
      8: "All",
      0: "Received",
      1: "Confirmed",
      2: "Interview",
      3: "Rejection",
      4: "Withdraw",
      5: "Employed",
    };

    return statusMapping[code.toString()] || "Unknown Status";
  };

  useEffect(() => {
    NotificationHelper.setToastRef(toast);
    getProfileData();
    getAvailableJobs();
  }, [Endpoints]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Candidate Dashboard</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="description" content="" />
      </Helmet>

      <Toast ref={toast} />

      {profile?.UserTypeId == 2 ? (
        <>
          <main className="main">
            <div className="page-header">
              <h2>Dashboard</h2>
            </div>

            <div className="row my-4">
              <div className="col-xl-4 col-lg-4 col-md-6">
                <ProfileCard profile={profile} />
              </div>

              <div className="col-xl-4 col-lg-5 col-md-6">
                <CompleteProfileCard profile={profile} />
              </div>

              <div className="col-xl-4 col-lg-3 col-md-6">
                <NextStep profile={profile} />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-6">
                <div className="card-box p-3 h-100">
                  <div className="d-inline w-100">
                    <Link to="/find-jobs">
                      <i
                        role="button"
                        className="pi pi-external-link primary_light_color float-end"
                      ></i>
                    </Link>
                    <h6 className="mb-0 primary_light_color">Available Jobs</h6>
                  </div>
                  <h1 className="mt-4">{availableJobs}</h1>
                </div>
              </div>

              <div className="col-6">
                <div className="card-box p-3 h-100">
                  <div className="d-inline w-100">
                    <Link to="/my-application">
                      <i
                        role="button"
                        className="pi pi-external-link primary_light_color float-end"
                      ></i>
                    </Link>
                    <h6 className="mb-0 primary_light_color">
                      Applied Applications
                    </h6>
                  </div>
                  <h1 className="mt-4">
                    {appliedjobs.length}{" "}
                  </h1>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-lg-12 col-md-12">
                <MyApplication profile={profile} appliedjobs={appliedjobs} />
              </div>
            </div>
          </main>
        </>
      ) : profile?.UserTypeId == 3 ? (
        <>
          <RecruiterFullProfile />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
