import React from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { ProfileHelper } from "../../shared/helper/profile.helper";

const ProfileCard = ({ profile }) => {
  const defaultValue = "N/A";

  return (
    <>
      {profile ? (
        <div className="card p-0 min_card_height">
          <div className="card-header p-3">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">
                {ProfileHelper.getUserFullName(profile)}{" "}
                {profile?.CurrentJobTitle
                  ? ` - ${profile.CurrentJobTitle}`
                  : ""}
              </h5>
              <Link to={"/profile"}>
                <i className="pi pi-user-edit mt-1 fs-5"></i>
              </Link>
            </div>
          </div>

          <div className="card-body d-flex align-items-center p-3">
            <div
              className="col-3 text-center"
              style={{ height: "110px", width: "110px" }}
            >
              <img
                src={ProfileHelper.getProfileImage(profile)}
                className="rounded profile-image"
                alt="profile-image"
              />
            </div>
            <div className="col-9 ms-3">
              <p className="font_semibold primary_color small my-1">
                Email :{" "}
                <span className="primary_light_color fw-normal">
                  {profile?.Email || defaultValue}
                </span>
              </p>
              <p className="font_semibold primary_color small my-1">
                Mobile :{" "}
                <span className="primary_light_color fw-normal">
                  {profile?.ContactNo || defaultValue}
                </span>
              </p>
              <p className="font_semibold primary_color small my-1">
                DOB :{" "}
                <span className="primary_light_color fw-normal">
                  {ProfileHelper.getUserDate(profile)}
                </span>
              </p>
              <p className="font_semibold primary_color small my-1">
                Addresss :{" "}
                <span className="primary_light_color fw-normal">
                  {ProfileHelper.getFullAdress(profile)}
                </span>
              </p>
            </div>
          </div>

          <div className="card-footer text-center p-3">
            <a
              // href={profile?.FaceBookLink}
              target="_blank"
              rel="noopener"
              className="mx-3 text-decoration-underline"
            >
              Facebook
            </a>
            <a
              // href={profile?.TwitterLink}
              target="_blank"
              rel="noopener"
              className="mx-3 text-decoration-underline"
            >
              Twitter
            </a>
            <a
              // href={profile?.LinkedinLink}
              target="_blank"
              rel="noopener"
              className="mx-3 text-decoration-underline"
            >
              Linkedin
            </a>
          </div>
        </div>
      ) : (
        <div className="card p-0 min_card_height">
          <div className="card-header p-3">
            <Skeleton></Skeleton>
          </div>
          <div className="card-body p-3">
            <Skeleton height="9rem"></Skeleton>
          </div>
          <div className="card-footer p-3">
            <Skeleton></Skeleton>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileCard;
