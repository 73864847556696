import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, Link } from "react-router-dom";
import { SubscriberHelper } from "../../shared/helper/subscriber.helper";
import { convertDateTime } from "../../shared/utils/utils";
import { ProfileHelper } from "../../shared/helper/profile.helper";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { HttpMethod } from "../../shared/enum/http-method.enum";
import { useConfig } from "../../config-context";

const JobDetails = () => {
  let { id, email } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profile, setProfileData] = useState(null);
  const toast = useRef(null);
  const { Endpoints } = useConfig();

  const onSubmit = () => {
    confirmDialog({
      message: "Are you sure you want apply this job?",
      header: "Apply Job",
      acceptLabel: "Apply",
      draggable: false,
      defaultFocus: null,
      accept: () => applyForJob(),
    });
  };

  const getProfileData = async () => {
    try {
      const result = await new ProfileHelper(Endpoints).fetchProfileInfo();
      setProfileData(result?.Data || {});
    } catch (error) {
      setError(error);
    }
  };

  const fetchJobDetails = async () => {
    setLoading(true);
    try {
      const response = await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(
        `${Endpoints.jobDetails}/${id}/${email}`,
        HttpMethod.GET
      );
      if (response) {
        setJob({
          jobid: response.Data.JobERPRecId,
          code: response.Data.Code,
          description: response.Data.Description,
          location: response.Data.Locations,
          longDescription: response.Data.LongDescription,
          openingDate: response.Data.OpeningDate,
          closingDate: response.Data.ClosingDate,
          status: response.Data.Status,
          applicationNumber: response.Data.Applications,
        });
      } else {
        setJob(null);
      }
    } catch (error) {
      setError("Failed to fetch job details");
    } finally {
      setLoading(false);
    }
  };

  const applyForJob = async () => {
    try {
      const payload = {
        applicationStatus: 0,
        appliedDate: new Date().toISOString(),
        candidateNumber: profile?.Number,
        dataArea: "",
        erpRecId: 0,
        isSynced: null,
        jobERPRecId: job ? job.jobid : null,
        number: 0,
        rowVersion: null,
      };
      await new SubscriberHelper(Endpoints.baseUrl).fetchXHR(Endpoints.applications, HttpMethod.POST, payload);
      navigate("/my-application");
    } catch (error) {}
  };

  useEffect(() => {
    getProfileData();
  }, [Endpoints]);

  useEffect(() => {
    fetchJobDetails();
  }, [id, email]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!job) {
    return <div>No job details found.</div>;
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <Helmet>
        <meta charSet="utf-8" />
        <title> Job Details </title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <main className="main">
        <div className="page-header d-flex">
          <Link to="/find-jobs">
            <span className="me-3  fs-4">
              <i className="pi pi-chevron-left"></i>
            </span>
            <span className="fs-3 font_semibold">Job Details</span>
          </Link>
        </div>
        <div className="mt-5 card-box ">
          <div className="d-inline w-100">
            <h3 className="d-inline">{job.code}</h3>
            <div className="d-inline float-end">
              {job.applicationNumber && job.applicationNumber.length === 0 ? (
                <button
                  className="apply btn rounded-0 bg_dark secondary_color font_semibold"
                  onClick={onSubmit}
                >
                  Apply Now
                  <span className="ms-3">
                    <i className="pi pi-arrow-right"></i>
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <div className="mt-4">
              <div className="mb-2">
                <span className="me-2">
                  <i className="pi pi-info-circle"></i>
                </span>
                <span className="font_semibold">Description: </span>
                <span className="primary_light_color font_semibold">
                  {job.description}
                </span>
              </div>

              <div className="mb-2">
                <span className="me-2">
                  <i className="pi pi-map-marker"></i>
                </span>
                <span className="font_semibold">Location: </span>
                <span className="primary_light_color font_semibold">
                  {job.location}
                </span>
              </div>

              <div className="mb-2">
                <span className="me-2">
                  <i className="pi pi-check-circle"></i>
                </span>
                <span className="font_semibold">Status: </span>
                <span className="primary_light_color font_semibold">
                  {job.status}
                </span>
              </div>

              <div className="mb-2">
                <span className="me-2">
                  <i className="pi pi-clock"></i>
                </span>
                <span className="font_semibold">Application Deadline: </span>
                <span className="primary_light_color font_semibold">
                  {convertDateTime(job.closingDate)}
                </span>
              </div>

              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: job.longDescription }}
              ></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default JobDetails;
