import React from "react";
import "swiper/css";
import "swiper/css/pagination";

const HiringProcess = () => {
  return (
    <section className="hiring-process bg-dark">
      <div className="container">
        <div className="section-title-dark" data-aos="fade">
          <h3 className="font_bold">
            <span className="secondary_color">HIRING </span>
            <span className="primary_light_color"> PROCESS</span>
          </h3>
          <p className="font_regular secondary_color">
            To get hired without any trouble, complete this process.
          </p>
        </div>

        <div className="row mt-5">
          <div
            className="col-lg-3 col-sm-6 mb-sm-0 mb-0 text-center"
            data-aos="fade-down"
            data-aos-duration="900"
          >
            <div className="d-flex justify-content-center">
              <div className="rotated-half-circle d-flex justify-content-center align-items-center">
                <div className="full-circle d-flex justify-content-center align-items-center font_bold">
                  01
                </div>
              </div>
            </div>

            <div className="process-text">
              <h3 className="font_bold secondary_color text-uppercase">
                ACCOUNT CREATE
              </h3>
              <p className="font_regular secondary_color">
                CREATE YOUR ACCOUNT BE CONFIDENTLY & SAFE
              </p>
            </div>
          </div>

          <div
            className="col-lg-3 col-sm-6 mb-sm-0 mb-0 text-center"
            data-aos="fade-down"
            data-aos-duration="1400"
          >
            <div className="d-flex justify-content-center">
              <div className="rotated-half-circle d-flex justify-content-center align-items-center">
                <div className="full-circle d-flex justify-content-center align-items-center font_bold">
                  02
                </div>
              </div>
            </div>

            <div className="process-text">
              <h3 className="font_bold secondary_color text-uppercase">
                UPLOAD RESUME
              </h3>
              <p className="font_regular secondary_color">
                Upload resume upload your resume, skill, education and other
                Info.
              </p>
            </div>
          </div>

          <div
            className="col-lg-3 col-sm-6 mb-sm-0 mb-0 text-center"
            data-aos="fade-down"
            data-aos-duration="1900"
          >
            <div className="d-flex justify-content-center">
              <div className="rotated-half-circle d-flex justify-content-center align-items-center">
                <div className="full-circle d-flex justify-content-center align-items-center font_bold">
                  03
                </div>
              </div>
            </div>

            <div className="process-text">
              <h3 className="font_bold secondary_color text-uppercase">
                Find Jobs
              </h3>
              <p className="font_regular secondary_color">
                Find Jobs After Uploading your biodata start finding suitable
                jobs.
              </p>
            </div>
          </div>

          <div
            className="col-lg-3 col-sm-6 mb-sm-0 mb-0 text-center"
            data-aos="fade-down"
            data-aos-duration="2400"
          >
            <div className="d-flex justify-content-center">
              <div className="rotated-half-circle d-flex justify-content-center align-items-center">
                <div className="full-circle d-flex justify-content-center align-items-center font_bold">
                  04
                </div>
              </div>
            </div>

            <div className="process-text">
              <h3 className="font_bold secondary_color text-uppercase">
                APPly Jobs
              </h3>
              <p className="font_regular secondary_color">
                Apply Jobs Apply for your dream Jobs and get hired easily.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HiringProcess;
