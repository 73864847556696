import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SignupHelper } from "../../shared/service/register-config";
import { useConfig } from "../../config-context";

const Register = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { Endpoints } = useConfig();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const data = await new SignupHelper(Endpoints).signup(email);
    } catch (error) {
      setError(error.message);
    }
  };
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Candidate Register</title>
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-5 col-12 h-auto p-0 m-0">
            <div
              className="bg-image-login h-100 position-relative"
              style={{
                background:
                  'linear-gradient(to bottom,rgba(0, 0, 0, 0.52),rgba(0, 0, 0, 0.73)),url("https://images.unsplash.com/photo-1524439188326-e47322d1cef2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
              }}
            >
              <div className="position-absolute top-50 start-50 translate-middle align-items-center w-100 px-5">
                <div data-aos="fade-right">
                  <div className="section-title-dark my-4">
                    <h3 className="my-4">
                      <span className="secondary_color font_semibold fs-1">
                        Hello User !
                      </span>
                    </h3>
                    <p className="secondary_color font_regular my-3">
                      Enter Your Personal Details And Start A Journey With Us.
                    </p>
                  </div>

                  <button
                    type="submit"
                    className="btn light_outline secondary_color mb-3 w-100 font_regular"
                  >
                    <Link to="/login">SIGN IN</Link>
                  </button>

                  <button
                    type="submit"
                    className="btn w-100 mt-2 bg_light primary_color font_regular"
                  >
                    JOIN AS RECRUITER
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 col-12 d-flex justify-content-center">
            <div className="login-forms text-center">
              <div
                className="py-5 text-center"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                <img src="/assets/images/logo-icon.svg" alt="" id="logo-icon" />

                <div>
                  <div className="section-title-light my-4">
                    <h3>
                      <span className="font_semibold primary_color">SIGN </span>
                      <span className="font_semibold primary_light_color">
                        UP
                      </span>
                    </h3>
                  </div>

                  <form className="text-start" onSubmit={handleRegister}>
                    <div className="mb-3">
                      <label for="email" className="form-label font_regular">
                        Email address *
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label for="password" className="form-label font_regular">
                        Password *
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter Password"
                      />
                    </div> */}

                    <button
                      type="submit"
                      className="btn bg_dark secondary_color w-100"
                    >
                      Send Verification Code
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
