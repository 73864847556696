import React, { useState, useEffect } from "react";
import { FormControlType } from "../../../../shared/enum/form-control-type.enum";
import CustomFormGroup from "../custom-forms";
import { MasterDataHelper } from "../../../../shared/helper/master-data.helper";
import { useConfig } from "../../../../config-context";

const SkillAddForm = ({ selectedSkill, getFormValue, formInputClass }) => {
  const inputClass = formInputClass || "col-md-6 col-12 mb-3";
  const [formValue, setFormValue] = useState({});
  const [skillList, setSkillList] = useState([]);
  const [skillLevelList, setSkillLevelList] = useState([]);
  const { Endpoints } = useConfig();

  const getSkillList = async () => {
    const results = await new MasterDataHelper(Endpoints).fetchSkillsList();
    setSkillList(results);
  };

  const getSkillLevelList = async () => {
    const results = await new MasterDataHelper(Endpoints).fetchSkillLevelsList();
    setSkillLevelList(results);
  };

  useEffect(() => {
    getSkillList();
    getSkillLevelList();

    if (selectedSkill) {
      setFormValue({
        ...selectedSkill,
      });
    }
  }, [Endpoints]);

  const formGroupList = [
    {
      label: "Skill",
      placeholder: "Select Skill",
      propertyName: "SkillERPRecId",
      type: FormControlType.DROPDOWN,
      options: skillList,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Skill Level",
      placeholder: "Select Skill Level",
      propertyName: "SkillLevelERPRecId",
      type: FormControlType.DROPDOWN,
      options: skillLevelList,
      class: inputClass,
    },
    {
      label: "Level Date",
      propertyName: "LevelDate",
      type: FormControlType.DATE,
      class: inputClass,
      isRequired: true,
    },
    {
      label: "Year Experience",
      placeholder: "Year Experience",
      propertyName: "YearOfExperience",
      type: FormControlType.NUMBER,
      class: inputClass,
    },
  ];

  const handleSubmit = (value) => {
    setFormValue(value);
    getFormValue(value);
  };

  return (
    <>
      <CustomFormGroup
        formGroupList={formGroupList}
        formValue={formValue}
        setFormValue={handleSubmit}
      ></CustomFormGroup>
    </>
  );
};

export default SkillAddForm;
