import { HttpMethod } from "../enum/http-method.enum";
import { NotificationHelper } from "./notification.helper";

export class SubscriberHelper {
  constructor(baseUrl) {
    this.BASE_URL = baseUrl;
  }

  fetchXHR = async (
    endpoint,
    method = HttpMethod.GET,
    payload = null,
    isDirectEndpoint
  ) => {
    const url = isDirectEndpoint ? endpoint : `${this.BASE_URL}/${endpoint}`;

    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : undefined,
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();

      if (!response.ok) {
        throw {
          status: response.status,
          statusCode: `Error! Code: ${result?.StatusCode || response.status}`,
          message: `${
            result?.Message || "Something Went Wrong, try again later!"
          }`,
        };
      }

      this.handleSuccessNotification(
        {
          status: result?.Status || "Success",
          message: result?.Message || "Operation Successfull",
        },
        method
      );
      return result;
    } catch (error) {
      this.handleErorNotification(error, method);
      throw error;
    }
  };

  handleSuccessNotification = (result, method) => {
    const { message, status } = result;
    switch (method) {
      case HttpMethod.POST:
      case HttpMethod.PUT:
      case HttpMethod.DELETE:
        if (NotificationHelper?.toastRef?.current) {
          NotificationHelper.showSuccess(status, message);
        }
        break;
      default:
        break;
    }
  };

  handleErorNotification = (error, method) => {
    const message = error.message || "An unspecified error occurred";
    const statusCode = error.statusCode || 500;
    switch (method) {
      case HttpMethod.POST:
      case HttpMethod.PUT:
      case HttpMethod.DELETE:
        if (NotificationHelper?.toastRef?.current) {
          NotificationHelper.showSuccess(statusCode, message);
        }
        break;
      default:
        break;
    }
  };
}
